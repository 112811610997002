import Button from "@/components/UI/Button";
import "./detailStyles/general.css";
import { useEffect, useRef, useState } from "react";
import Loader from "@/components/Loader";
import {
  updateAssetsById,
  updateProjectDetails,
  getProjectRequestedDocuments,
  updateProjectDocumentRequest,
  changeDocumentRequestStatus,
} from "@/services/api";
import { Link, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import ReactFlagsSelect from "react-flags-select";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import DoubleRangeSlider from "@/components/UI/DoubleRangePicker";
import SingleRangePicker from "@/components/UI/SingleRangePicker";
import useDebounce from "@/hooks/useDebounce";
import Switch from "react-switch";
import { revenueGeneratingAssetArray } from "@/constants/getCapitalConstants";
import countryList from "react-select-country-list";
import { IcFilePlaceholder } from "@/assets/Icons";
import IcCross from "@/assets/ic_cross";
import { IcDoc, IcFile } from "@/assets";
import useAuth from "@/hooks/useAuth";

const defaultState = {
  assetIds: [
    {
      model: "",
      brand: "",
      assetType: "",
      technology: "",
      powerCapacity: "",
      serialNumber: "",
    },
  ],
  general: {
    name: "",
    projectDescription: "",
    spvLocation: "",
    assetLocation: "",
    hasRevenueGeneratingAssets: false,
  },
  financing: {
    tokenPrice: "",
    tokenAmount: "",
    symbol: "",
    contractTerm: "",
    currencyForTokenSale: "",
    fundingGoal: "",
    currencyForRevenueDistribution: "",
    revenueSharePercentage: "",
    fundingReceived: "",
    fundingUsage: [{ financingDescription: "", amount: "", title: "", currency: "" }],
  },
  valuation: {
    npv: "",
    irr: "",
    lcoe: "",
    paybackPeriod: "",
    capacityFactor: "",
    totalInitialCost: "",
    operationLifetime: "",
    financingStructure: "",
    ownershipStructure: "",
    commercialOperationDate: "",
    annualOperationCosts: "",
  },
};

const defaultTypeOfRevenue = [
  {
    name: "Energy Sales PPA",
    value: "PPA",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "Energy Sales Grid",
    value: "GRID",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "RECs",
    value: "RECS",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "Carbon Credits",
    value: "CARBONCREDITS",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "Rental Fees",
    value: "RENTAL",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "Sales",
    value: "SALES",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
  {
    name: "",
    value: "OTHER",
    status: false,
    projectedRevenue: "",
    yearlyOrMonthly: "",
  },
];

type DefaultStateKeys = keyof typeof defaultState;

export default function DetailsCards({ refs, data, refetch }: any) {
  const { userId, companyId } = useAuth();
  const {
    name = "",
    documentId = [],
    revenueStreams = [],
    projectDescription = "",
    spvLocation = "",
    assetLocation = "",
    assetIds = [],
    hasRevenueGeneratingAssets = false,
    commercialOperationDate = "",
    totalInitialCost = "",
    operationLifetime = "",
    financingStructure = "",
    ownershipStructure = "",
    annualOperationCosts = "",
    irr = "",
    npv = "",
    lcoe = "",
    capacityFactor = "",
    paybackPeriod = "",
    symbol = "",
    fundingGoal = "",
    fundingReceived = "",
    tokenPrice = "",
    tokenAmount = "",
    currencyForTokenSale = "",
    currencyForRevenueDistribution = "",
    revenueSharePercentage = "",
    contractTerm = "",
    fundingUsage = [{ financingDescription: "", amount: "", title: "", currency: "" }],
  } = { ...data };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    data: documentRequestList = [],
    isLoading: isLoadingRequest,
    refetch: refetchRequest,
  } = useQuery({
    queryKey: ["getProjectRequestedDocuments"],
    queryFn: () => getProjectRequestedDocuments(data?._id),
    enabled: !!data?._id,
    select: (response) => {
      return response?.data ?? [];
    },
  });

  // console.log("documentRequestList: ", documentRequestList);

  const { model = "", brand = "", assetType = "", technology = "", powerCapacity = "", serialNumber = "" } = { ...assetIds[0] };

  const [isEditMode, setIsEditMode] = useState({
    assetIds: false,
    general: false,
    valuation: false,
    financing: false,
  });

  const [typeOfRevenue, setTypeOfRevenue] = useState(defaultTypeOfRevenue);

  const [isUpdating, setUpdating] = useState(false);

  const { Documents, Financing, Valuation, General, Asset } = refs;

  const todayDate: any = new Date().toISOString().split("T")[0];

  const [details, setDetail] = useState(defaultState);

  useEffect(() => {
    setDetail({
      assetIds: [
        {
          model,
          brand,
          assetType,
          technology,
          powerCapacity,
          serialNumber,
        },
      ],
      general: {
        name,
        projectDescription,
        spvLocation,
        assetLocation,
        hasRevenueGeneratingAssets,
      },
      financing: {
        tokenPrice,
        tokenAmount,
        symbol,
        contractTerm,
        currencyForTokenSale,
        fundingGoal,
        fundingUsage,
        currencyForRevenueDistribution,
        revenueSharePercentage,
        fundingReceived,
      },
      valuation: {
        npv,
        irr,
        lcoe,
        paybackPeriod,
        capacityFactor,
        totalInitialCost,
        operationLifetime,
        financingStructure,
        ownershipStructure,
        commercialOperationDate,
        annualOperationCosts,
      },
    });

    setTypeOfRevenue((prevState: any) => {
      return prevState.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return item;
      });
    });
    // eslint-disable-next-line
  }, [data]);

  const handleUpdateDetails = async (updatingTo: DefaultStateKeys) => {
    setUpdating(true);
    const selectedRevenueList = typeOfRevenue.filter((item: any) => item.status);
    let updatedRevenueList = [];

    if (isEditMode.valuation) {
      updatedRevenueList = selectedRevenueList.map((item: any) => {
        const hasAlready = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasAlready) {
          return {
            ...hasAlready,
            name: item.name,
            yearlyRevenue:
              item.yearlyOrMonthly === "1"
                ? typeof item.projectedRevenue === "number"
                  ? item.projectedRevenue
                  : parseFloat(item.projectedRevenue.replace(",", ""))
                : 0,
            monthlyRevenue:
              item.yearlyOrMonthly === "0"
                ? typeof item.projectedRevenue === "number"
                  ? item.projectedRevenue
                  : parseFloat(item.projectedRevenue.replace(",", ""))
                : 0,
          };
        }
        return {
          name: item.name,
          type: item.value,
          yearlyRevenue: item.yearlyOrMonthly === "1" ? parseFloat(item.projectedRevenue.replace(",", "")) : 0,
          monthlyRevenue: item.yearlyOrMonthly === "0" ? parseFloat(item.projectedRevenue.replace(",", "")) : 0,
        };
      });
    } else {
      updatedRevenueList = revenueStreams;
    }
    if (updatingTo === "assetIds") {
      await updateAssetsById(data.assetIds[0]._id, details[updatingTo][0]);
      await refetch();
      return;
    }

    const updatedDetails = { ...data, revenueStreams: updatedRevenueList, ...details[updatingTo] };
    await updateProjectDetails(data._id, updatedDetails);
    await refetch();
    setUpdating(false);
  };

  const handleChangeDetails = async (evt: any, changingTo: any, isFundingUpdateIndex?: number) => {
    const { name, value } = evt.target;

    // List of names to check against
    const namesToCheck = [
      "operationLifetime",
      "totalInitialCost",
      "annualOperationCosts",
      "npv",
      "irr",
      "lcoe",
      "paybackPeriod",
      "capacityFactor",
      "fundingGoal",
      "tokenPrice",
      "tokenAmount",
      "contractTerm",
      "amount",
    ];

    // Replace commas only if the name is in the list
    const processedValue = namesToCheck.includes(name) && typeof value === "string" ? removeCommas(value) : value;

    if (!isUpdating) {
      if (isFundingUpdateIndex !== undefined) {
        setDetail((prevState: any) => {
          let newUpdatedValue = [...prevState.financing.fundingUsage];
          newUpdatedValue[isFundingUpdateIndex] = { ...newUpdatedValue[isFundingUpdateIndex], [name]: processedValue };
          return {
            ...prevState,
            [changingTo]: {
              ...prevState[changingTo],
              fundingUsage: newUpdatedValue,
            },
          };
        });
        return;
      }
      if (changingTo === "assetIds") {
        setDetail((prevState: any) => {
          return {
            ...prevState,
            [changingTo]: [
              {
                ...prevState[changingTo][0],
                [name]: processedValue,
              },
            ],
          };
        });
        return;
      }
      setDetail((prevState: any) => {
        return {
          ...prevState,
          [changingTo]: {
            ...prevState[changingTo],
            [name]: processedValue,
          },
        };
      });
    }
  };

  const handleClickCancleOrEdit = async (clickedTo: DefaultStateKeys) => {
    setIsEditMode((prevState: any) => ({ ...prevState, [clickedTo]: !prevState[clickedTo] }));
    const generalPrevState = {
      name,
      projectDescription,
      spvLocation,
      assetLocation,
      hasRevenueGeneratingAssets,
    };
    const valuationPrevState = {
      npv,
      irr,
      lcoe,
      paybackPeriod,
      capacityFactor,
      totalInitialCost,
      operationLifetime,
      financingStructure,
      ownershipStructure,
      commercialOperationDate,
      annualOperationCosts,
    };

    const financing = {
      tokenPrice,
      tokenAmount,
      symbol,
      contractTerm,
      currencyForTokenSale,
      revenueSharePercentage,
      fundingUsage,
      currencyForRevenueDistribution,
      fundingGoal,
      fundingReceived,
    };

    setDetail((prevState: any) => {
      return {
        ...prevState,
        [clickedTo]: {
          ...(clickedTo === "general"
            ? generalPrevState
            : clickedTo === "valuation"
            ? valuationPrevState
            : clickedTo === "financing"
            ? financing
            : data[clickedTo]
            ? data[clickedTo]
            : defaultState[clickedTo]),
        },
      };
    });
    setTypeOfRevenue((prevState: any) => {
      return defaultTypeOfRevenue.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return { ...item, status: false };
      });
    });
  };

  const handleAddFundingUsage = () => {
    setDetail((prevState: any) => {
      return {
        ...prevState,
        financing: {
          ...prevState.financing,
          fundingUsage: [...prevState.financing.fundingUsage, { financingDescription: "", amount: "", title: "", isNew: true }],
        },
      };
    });
  };

  const handleDeleteFundingUsage = (clickedIndex: number) => {
    setDetail((prevState: any) => {
      let newList = [...prevState.financing.fundingUsage];
      newList.splice(clickedIndex, 1);
      return {
        ...prevState,
        financing: {
          ...prevState.financing,
          fundingUsage: newList,
        },
      };
    });
  };

  const handleRevenueChange = (value: number) => {
    setDetail((prevDetails): any => ({
      ...prevDetails,
      financing: {
        ...prevDetails.financing,
        revenueSharePercentage: value,
      },
    }));
  };

  const [rangeValueMin, setRangeValueMin] = useState(0);
  const debouncedQueryMin = useDebounce(rangeValueMin, 500);

  useEffect(() => {
    const evt = { target: { name: "fundingReceived", value: debouncedQueryMin } };
    handleChangeDetails(evt, "financing");
    // eslint-disable-next-line
  }, [debouncedQueryMin]);

  //----------------------------------------------------------------------------------------------

  const [rangeValueMax, setRangeValueMax] = useState(10);
  const debouncedQueryMax = useDebounce(rangeValueMax, 500);

  // useEffect(() => {
  //   const evt = { target: { name: "fundingGoal", value: debouncedQueryMax } };
  //   handleChangeDetails(evt, "financing");
  //   // eslint-disable-next-line
  // }, [debouncedQueryMax]);

  const formatNumberWithCommas = (value: string) => {
    if (value == "" || value == undefined) {
      return;
    }
    const num = value.replace(/\D/g, ""); // Remove non-digit characters
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
  };
  const removeCommas = (value: string) => {
    if (value) {
      return value.replace(/,/g, ""); // Remove commas
    }
  };
  const handleTypeOfRevenueChange = (index: number, field: string, value: any) => {
    if (field === "projectedRevenue") {
      value = formatNumberWithCommas(value);
    }

    if (field === "status" && value === false) {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index].status = value;
        newTypeOfRevenue[index].projectedRevenue = "";
        newTypeOfRevenue[index].yearlyOrMonthly = "";
        if (index === newTypeOfRevenue.length - 1) {
          newTypeOfRevenue[index].name = "";
        }
        return newTypeOfRevenue;
      });
    } else {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index][field] = value;
        return newTypeOfRevenue;
      });
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const projectId: any = searchParams.get("projectId");

  const inputFile = useRef<any>([]);

  const [file, setFile] = useState([]);

  const handleFileUpload = (e: any, id: string) => {
    const file = e.target.files[0];
    setFile(file);
    e.target.value = null;

    const formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);
    updateProjectDocumentRequest(projectId, id, formData)
      .then((res) => {
        setIsLoading(false);
        if (res.code == 200) {
          refetchRequest();
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
        toast.error(err.message || "Failed to upload document file, please try again");
      });
  };

  const removeDocument = (documentRequestId: string) => {
    changeDocumentRequestStatus(documentRequestId)
      .then((res) => {
        if (res.code == 200) {
          toast.success(res.message);
          refetchRequest();
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        toast.error(err.message || "Failed to remove document file, please try again");
      });
  };

  return (
    <div className="container">
      <div className="general-card" ref={General} data-section="General">
        <div className="general-card-section">
          <h2 className="text-[14px]">General</h2>
          <p className="text-[11px] ">Project overview information</p>
        </div>
        <div className="vertical-line"></div>
        <div className="general-card-section text-[11px]">
          <div className="field">
            <div className="label">Project Name</div>
            <input
              type="text"
              name="name"
              disabled={!isEditMode.general}
              value={details.general.name}
              onChange={(evt) => handleChangeDetails(evt, "general")}
              placeholder="Solar Plant Berlin"
              className={`${isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field">
            <div className="label">Description</div>
            <textarea
              value={details.general.projectDescription}
              disabled={!isEditMode.general}
              onChange={(evt) => handleChangeDetails(evt, "general")}
              name="projectDescription"
              placeholder="Description"
              style={{ resize: isEditMode.general ? "vertical" : "none" }}
              className={`${
                isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
              } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
              rows={isEditMode.general ? 4 : 1}
            />
          </div>
          <div className="field">
            <div className="label">Is this asset already generating revenue?</div>
            <Dropdown
              menuBoxMarginTop={5}
              disabled={!isEditMode.general}
              value={details.general.hasRevenueGeneratingAssets.toString()}
              className={`${
                isEditMode.general ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
              } px-0 w-full text-ternary text-sm`}
              onChange={(selectedValue: any) => {
                const evt = { target: { name: "hasRevenueGeneratingAssets", value: selectedValue.value } };
                handleChangeDetails(evt, "general");
              }}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
            />
          </div>
          <div className="block sm:flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Physical location of revenue-generating asset</div>
              <div className="value">
                {/* <img width={"20px"} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAACgCAMAAAAFBRFXAAAAGFBMVEUAAADdAAD/zgB2AADqAADZAAD2tQD/0wB1Ax0IAAAAnklEQVR4nO3PARGDAAwAsTJg+HeMDO6+iYPMAAAAAAAAAAAAAAAA8L1zmbmWmWMZ4TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64bq5l5n/MvMsM79lhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOte5L6m4VeOch0AAAAASUVORK5CYII=" alt="Germany" /> */}
                <ReactFlagsSelect
                  searchable={true}
                  searchPlaceholder="Search location"
                  placeholder="Select location"
                  className={`${
                    isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-0 w-full text-ternary text-sm`}
                  selectButtonClassName={`border-t-0 border-l-0 border-r-0 !rounded-none bg-transparent border-b border-gray-300 hover:bg-transparent body-small-regular`}
                  optionsSize={12}
                  selectedSize={12}
                  disabled={!isEditMode.general}
                  selected={countryList().labelMap[details.general.assetLocation?.toLocaleLowerCase()]}
                  onSelect={(code) => {
                    const evt = { target: { name: "assetLocation", value: countryList().getLabel(code) } };
                    handleChangeDetails(evt, "general");
                  }}
                />
              </div>
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">SPV Location of revenue-generating asset (if existent)</div>
              <div className="value">
                {/* <img width={"20px"} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAACgCAMAAAAFBRFXAAAAGFBMVEUAAADdAAD/zgB2AADqAADZAAD2tQD/0wB1Ax0IAAAAnklEQVR4nO3PARGDAAwAsTJg+HeMDO6+iYPMAAAAAAAAAAAAAAAA8L1zmbmWmWMZ4TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64bq5l5n/MvMsM79lhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOuE64TrhOte5L6m4VeOch0AAAAASUVORK5CYII=" alt="Germany" /> */}
                <ReactFlagsSelect
                  searchable={true}
                  searchPlaceholder="Search location"
                  placeholder="Select location"
                  className={`${
                    isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-0 w-full text-ternary text-sm`}
                  selectButtonClassName={`border-t-0 border-l-0 border-r-0 !rounded-none bg-transparent border-b border-gray-300 hover:bg-transparent body-small-regular`}
                  optionsSize={12}
                  selectedSize={12}
                  disabled={!isEditMode.general}
                  selected={countryList().labelMap[details.general.spvLocation?.toLocaleLowerCase()]}
                  onSelect={(code) => {
                    const evt = { target: { name: "spvLocation", value: countryList().getLabel(code) } };
                    handleChangeDetails(evt, "general");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-end">
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("general")}>
              {isEditMode.general ? "Cancel" : "Edit"}
            </Button>
            {isEditMode.general && (
              <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={() => handleUpdateDetails("general")}>
                {isUpdating ? <Loader isLoading={true} /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="general-card" ref={Asset} data-section="Asset">
        <div className="general-card-section">
          <h2 className="text-[14px]">Asset</h2>
          <p className="text-[11px] ">Technical Specifications</p>
        </div>
        <div className="vertical-line"></div>
        <div className="general-card-section text-[11px]">
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Revenue generating asset used to pay back loan</div>
              <Dropdown
                menuBoxMarginTop={5}
                disabled={!isEditMode.assetIds}
                value={details.assetIds[0].assetType}
                className={`${
                  isEditMode.assetIds ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                } px-0 w-full text-ternary text-sm`}
                onChange={(selectedValue: any) => {
                  const evt = { target: { name: "assetType", value: selectedValue.value } };
                  handleChangeDetails(evt, "assetIds");
                }}
                options={revenueGeneratingAssetArray}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Power capacity (kw)</div>
              <input
                name="powerCapacity"
                disabled={!isEditMode.assetIds}
                value={isEditMode.assetIds ? details.assetIds[0].powerCapacity : `${formatNumberWithCommas(String(details.assetIds[0].powerCapacity))}  kw`}
                onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                type="text"
                placeholder="150,000 kW"
                className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="field">
            <div className="label">Technology</div>
            <input
              type="text"
              disabled={!isEditMode.assetIds}
              value={details.assetIds[0].technology}
              name="technology"
              onChange={(evt) => handleChangeDetails(evt, "assetIds")}
              placeholder="Enter the technology used"
              className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field">
            <div className="label">Brand</div>
            <input
              disabled={!isEditMode.assetIds}
              value={details.assetIds[0].brand}
              type="text"
              name="brand"
              onChange={(evt) => handleChangeDetails(evt, "assetIds")}
              placeholder="Enter the brand used"
              className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field">
            <div className="label">Model</div>
            <input
              disabled={!isEditMode.assetIds}
              value={details.assetIds[0].model}
              type="text"
              name="model"
              onChange={(evt) => handleChangeDetails(evt, "assetIds")}
              placeholder="Enter the model used"
              className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field">
            <div className="label">Serial Number</div>
            <input
              disabled={!isEditMode.assetIds}
              value={details.assetIds[0].serialNumber}
              type="text"
              name="serialNumber"
              onChange={(evt) => handleChangeDetails(evt, "assetIds")}
              placeholder="Enter the serial number"
              className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="flex gap-4 justify-end">
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("assetIds")}>
              {isEditMode.assetIds ? "Cancel" : "Edit"}
            </Button>
            {isEditMode.assetIds && (
              <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={() => handleUpdateDetails("assetIds")}>
                {isUpdating ? <Loader isLoading={true} /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="general-card" ref={Valuation} data-section="Valuation">
        <div className="general-card-section">
          <h2 className="text-[14px]">Valuation</h2>
          <p className="text-[11px] ">Detailed Financials & Performance</p>
        </div>
        <div className="vertical-line"></div>
        <div className="general-card-section text-[11px]">
          <div className="field w-[100%] sm:w-[50%]">
            <div className="label">
              {details.general.hasRevenueGeneratingAssets ? "Since when is it generating revenue ? " : "When will it generate revenue ?"}
            </div>
            <input
              disabled={!isEditMode.valuation}
              value={details.valuation.commercialOperationDate?.toString()?.slice(0, 10)}
              type="date"
              name="commercialOperationDate"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="10/08/2024"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
              max={details.general.hasRevenueGeneratingAssets ? todayDate : undefined}
              min={!details.general.hasRevenueGeneratingAssets ? todayDate : undefined}
            />
          </div>
          {isEditMode.valuation ? (
            <div className="my-6">
              <div className="hidden xl:block">
                <div className="flex mb-4">
                  <span>Type of revenue</span>
                  <span className="w-[20%]"></span>
                  <span className="w-[40%]">Estimated Revenue</span>
                  <span className="w-[30%]">Calculation period</span>
                </div>
              </div>

              <div className="grid sm:grid-cols-1 xl:grid-cols-1 gap-4">
                {typeOfRevenue.map((item: any, index: any) => (
                  <div className="flex justify-between gap-3 items-center p-3 xl:p-0 rounded-lg bg-[#FCFCFC] xl:bg-white" key={index}>
                    <div className="flex items-center text-monochrome-60">
                      <Switch
                        offColor="#fff"
                        onColor="#00cc9c"
                        activeBoxShadow={""}
                        offHandleColor={"#9E9E9E"}
                        onHandleColor={"#fff"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={18}
                        borderRadius={9}
                        className="overflow-hidden border-2 rounded-[100px] border-monochrome-10 border-green-500"
                        onChange={() => handleTypeOfRevenueChange(index, "status", !item.status)}
                        checked={item.status}
                      />
                    </div>
                    <div className="w-[20%]">
                      {index !== typeOfRevenue.length - 1 ? (
                        <p>{item.name}</p>
                      ) : (
                        <input
                          type="text"
                          name="other"
                          disabled={!item.status}
                          className={`border-monochrome-10 rounded-lg w-full`}
                          value={item.name}
                          onChange={(e) => handleTypeOfRevenueChange(index, "name", e.target.value)}
                          onKeyDown={(e) => {
                            if (/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    </div>
                    {/* Projected Revenue */}
                    <div className="relative w-[40%]">
                      <label className="xl:hidden">Projected revenue?</label>
                      <input
                        type="text"
                        name="projectedRevenue"
                        className={`mt-2 xl:mt-0 w-full pl-6 ${!item.status ? "border-monochrome-5" : "border-monochrome-10"} !rounded-lg`}
                        disabled={!item.status}
                        value={formatNumberWithCommas(String(item.projectedRevenue))}
                        onChange={(e) => handleTypeOfRevenueChange(index, "projectedRevenue", e.target.value)}
                      />
                      <p className={`absolute bottom-2 left-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>$</p>
                      <p className={`absolute bottom-2 right-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>USD</p>
                    </div>
                    {/* Projected Revenue / Yearly Revenue */}
                    <div className="w-[30%]">
                      <label className="xl:hidden">Projected yearly/monthly revenue?</label>
                      <Dropdown
                        menuBoxMarginTop={5}
                        value={item.yearlyOrMonthly}
                        className={`mt-2 xl:mt-0 px-0 rounded-lg border border-monochrome-10 h-[42px]`}
                        onChange={(val) => handleTypeOfRevenueChange(index, "yearlyOrMonthly", val.value)}
                        options={[
                          { value: "0", label: "Per Month" },
                          { value: "1", label: "Per Year" },
                        ]}
                        disabled={!item.status}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex  items-center space-x-2 justify-between">
              <div className="field w-[20%]">
                <div className="label">Type of revenue</div>
                {revenueStreams.map((item: any, index: number) => (
                  <div className="value" key={index}>
                    {item.type}
                  </div>
                ))}
              </div>
              <div className="field w-[30%]">
                <div className="label">Projected revenue?</div>
                {revenueStreams.map((item: any, index: number) => (
                  <input
                    key={index}
                    readOnly
                    value={
                      item.monthlyRevenue
                        ? `$  ${formatNumberWithCommas(String(item.monthlyRevenue))} USD`
                        : `$  ${formatNumberWithCommas(String(item.yearlyRevenue))} USD`
                    }
                    type="text"
                    disabled
                    placeholder="$  1,300,000  USD"
                    className={`${
                      isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                    } px-0 w-full text-ternary text-sm`}
                  />
                ))}
              </div>
              <div className="field w-[50%]">
                <div className="label">Projected yearly/monthly revenue?</div>
                {revenueStreams.map((item: any, index: number) => (
                  <input
                    key={index}
                    readOnly
                    value={item.monthlyRevenue ? `per month` : `per year`}
                    type="text"
                    disabled
                    placeholder="$  1,300,000  USD"
                    className={`${
                      isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                    } px-0 w-full text-ternary text-sm`}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]]">
              <div className="label">Total initial cost</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.totalInitialCost))}
                type="text"
                name="totalInitialCost"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="$  1,300,000  USD"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Operation lifetime</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.operationLifetime))}
                type="text"
                name="operationLifetime"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="2 year"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="field">
            <div className="label">Financing structure</div>
            <textarea
              disabled={!isEditMode.valuation}
              value={details.valuation.financingStructure}
              name="financingStructure"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Please provide a detailed of the financing structure"
              className={`${
                isEditMode.valuation
                  ? "border border-monochrome-10 rounded px-2"
                  : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
              } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
              style={{ resize: isEditMode.valuation ? "vertical" : "none" }}
              rows={isEditMode.valuation ? 4 : 1}
            />
          </div>
          <div className="field">
            <div className="label">Ownership structure</div>
            <textarea
              disabled={!isEditMode.valuation}
              value={details.valuation.ownershipStructure}
              name="ownershipStructure"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Please provide Information about the owner or owning entity"
              className={`${
                isEditMode.valuation
                  ? "border border-monochrome-10 rounded px-2"
                  : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
              } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
              style={{ resize: isEditMode.valuation ? "vertical" : "none" }}
              rows={isEditMode.valuation ? 4 : 1}
            />
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Annual operation costs</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.annualOperationCosts))}
                type="text"
                name="annualOperationCosts"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the annual operations cost"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">NPV (Net Present Value)</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.npv))}
                type="text"
                name="npv"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the Net Present Value"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">IRR (Internal Rate of Return)</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.irr))}
                type="text"
                name="irr"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the Internal Rate of Return"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">LCOE (Levelized Cost of Energy)</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.lcoe))}
                type="text"
                name="lcoe"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the Levelized Cost of Energy"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Payback Period</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.paybackPeriod))}
                type="text"
                name="paybackPeriod"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the payback period"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Capacity Factor</div>
              <input
                disabled={!isEditMode.valuation}
                value={formatNumberWithCommas(String(details.valuation.capacityFactor))}
                type="text"
                name="capacityFactor"
                onChange={(evt) => handleChangeDetails(evt, "valuation")}
                placeholder="Enter the capacity factor"
                className={`${
                  isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="flex gap-4 justify-end">
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("valuation")}>
              {isEditMode.valuation ? "Cancel" : "Edit"}
            </Button>
            {isEditMode.valuation && (
              <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={() => handleUpdateDetails("valuation")}>
                {isUpdating ? <Loader isLoading={true} /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="general-card" ref={Financing} data-section="Financing">
        <div className="general-card-section">
          <h2 className="text-[14px]">Financing</h2>
          <p className="text-[11px] ">Tokenization & Fundraising</p>
        </div>
        <div className="vertical-line"></div>
        <div className="general-card-section text-[11px]">
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="relative">
                <div className="label">Capital needed</div>
                <input
                  disabled={!isEditMode.financing}
                  value={formatNumberWithCommas(String(details.financing.fundingGoal))}
                  type="text"
                  name="fundingGoal"
                  onChange={(evt) => handleChangeDetails(evt, "financing")}
                  placeholder=""
                  className={`${
                    isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-[20px] w-full text-ternary text-sm`}
                />
                <p className={`absolute bottom-2 left-2 text-monochrome-60`}>$</p>
                <p className={`absolute bottom-2 right-2 text-monochrome-60`}>USD</p>
              </div>
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Token symbol</div>
              <input
                disabled
                value={details.financing.symbol}
                type="text"
                name="symbol"
                onChange={(evt) => handleChangeDetails(evt, "financing")}
                placeholder="BOSHC-SOLAR-1"
                className={`${
                  isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="field w-[100%] sm:w-[48%]">
              <div className="relative">
                <div className="label">Token price</div>
                <input
                  disabled
                  value={details.financing.tokenPrice}
                  type="number"
                  name="tokenPrice"
                  onChange={(evt) => handleChangeDetails(evt, "financing")}
                  placeholder=""
                  className={`${
                    isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-[20px] w-full text-gray-700 text-sm`}
                />
                <p className={`absolute bottom-2 left-2 text-monochrome-60`}>$</p>
                <p className={`absolute bottom-2 right-2 text-monochrome-60`}>USD</p>
              </div>
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Token amount</div>
              <input
                disabled
                value={details.financing.tokenAmount}
                type="number"
                name="tokenAmount"
                onChange={(evt) => handleChangeDetails(evt, "financing")}
                placeholder="40266"
                className={`${
                  isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-gray-700 text-sm`}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            {/* <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Currency for token sale</div>
              <Dropdown
                menuBoxMarginTop={5}
                disabled={!isEditMode.financing}
                value={details.financing.currencyForTokenSale}
                className={`${
                  isEditMode.financing ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                } px-0 w-full text-ternary text-sm`}
                onChange={(selectedValue: any) => {
                  const evt = { target: { name: "currencyForTokenSale", value: selectedValue.value } };
                  handleChangeDetails(evt, "financing");
                }}
                placeholder="USD"
                options={[
                  { value: "USD", label: "USD" },
                  { value: "EUR", label: "EUR" },
                  { value: "INR", label: "INR" },
                ]}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Currency for revenue distribution</div>
              <Dropdown
                menuBoxMarginTop={5}
                disabled={!isEditMode.financing}
                value={details.financing.currencyForRevenueDistribution}
                className={`${
                  isEditMode.financing ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                } px-0 w-full text-ternary text-sm`}
                onChange={(selectedValue: any) => {
                  const evt = { target: { name: "currencyForRevenueDistribution", value: selectedValue.value } };
                  handleChangeDetails(evt, "financing");
                }}
                placeholder="USD"
                options={[
                  { value: "USD", label: "USD" },
                  { value: "EUR", label: "EUR" },
                  { value: "INR", label: "INR" },
                ]}
              />
            </div> */}
          </div>
          <div className="field">
            <div className="label">Revenue share percentage range (%)</div>
            {isEditMode.financing ? (
              <div className="w-full mt-5">
                {/* <DoubleRangeSlider
                  minRange={23}
                  maxRange={67}
                  onChangeMax={(value) => setRangeValueMax(value)}
                  onChangeMin={(value) => setRangeValueMin(value)}
                /> */}
                <SingleRangePicker value={details.financing.revenueSharePercentage || 0} minRange={0} maxRange={100} onChange={handleRevenueChange} />
              </div>
            ) : (
              <input
                disabled
                value={`${details.financing.revenueSharePercentage || 0}%`}
                type="text"
                name="revenuePercentageRangeMaxAndMin"
                onChange={(evt) => handleChangeDetails(evt, "financing")}
                placeholder="10%-15%"
                className={`${
                  isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-0 w-full text-ternary text-sm`}
              />
            )}
          </div>
          <div className="field w-[50%]">
            <div className="label">Contract term (month)</div>
            <input
              disabled={!isEditMode.financing}
              value={formatNumberWithCommas(String(details.financing.contractTerm))}
              type="text"
              name="contractTerm"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder="Minimum contract term is 1 month"
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          {!isEditMode.financing && (
            <>
              <div className="label mb-4">Funding usage</div>
              {details.financing.fundingUsage.map((item: any, index: number) => (
                <div className="flex flex-wrap items-center justify-between" key={index}>
                  <div className="field w-[30%]">
                    <div className="label">Title</div>
                    <input
                      disabled={!isEditMode.financing}
                      value={item.title}
                      name="title"
                      onChange={(evt) => handleChangeDetails(evt, "financing")}
                      type="text"
                      placeholder="Operations"
                      className={`${
                        isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                      } px-0 w-full text-ternary text-sm `}
                    />
                  </div>
                  <div className="field w-[30%]">
                    <div className="label">Amount</div>
                    <input
                      disabled={!isEditMode.financing}
                      value={`${formatNumberWithCommas(String(item.amount))} ${item.currency}`}
                      type="text"
                      name="amount"
                      onChange={(evt) => handleChangeDetails(evt, "financing")}
                      placeholder="1,300,000 USD"
                      className={`${
                        isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                      } px-0 w-full text-ternary text-sm`}
                    />
                  </div>
                  <div className="field w-[30%]">
                    <div className="label">Description</div>
                    <input
                      disabled={!isEditMode.financing}
                      value={item.financingDescription}
                      type="text"
                      name="financingDescription"
                      onChange={(evt) => handleChangeDetails(evt, "financing")}
                      placeholder="For year 1 operations, including maintenance"
                      className={`${
                        isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                      } px-0 w-full text-ternary text-sm`}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          {isEditMode.financing && (
            <>
              {details.financing.fundingUsage.map((item: any, index: number) => (
                <div className="max-w-2xl p-6 bg-white shadow rounded-lg mb-5" key={index}>
                  <div className="flex justify-between items-center mb-6">
                    <div className="label mb-4">Funding usage {index + 1}</div>
                    {item.isNew && (
                      <button className="text-gray-500 hover:text-ternary" onClick={() => handleDeleteFundingUsage(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    <div>
                      <div className="label">Title</div>
                      <input
                        name="title"
                        type="text"
                        value={item.title}
                        onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                        placeholder="Enter the title"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                    <div>
                      <div className="label">Amount</div>
                      <div className="flex mt-1">
                        <input
                          name="amount"
                          value={formatNumberWithCommas(String(item.amount))}
                          onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                          type="text"
                          placeholder="Enter the amount"
                          className="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        <Dropdown
                          menuBoxMarginTop={5}
                          value={item.currency}
                          className={`border rounded px-2 w-full text-ternary text-sm mx-2`}
                          onChange={(selectedValue: any) => {
                            const evt = { target: { name: "currency", value: selectedValue.value } };
                            handleChangeDetails(evt, "financing", index);
                          }}
                          options={[
                            { value: "USD", label: "USD" },
                            { value: "EUR", label: "EUR" },
                            { value: "INR", label: "INR" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="label">Description</div>
                    <textarea
                      value={item.financingDescription}
                      onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                      name="financingDescription"
                      placeholder="Please provide the description"
                      className="placeholder-sm !text-[11px] mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      rows={4}
                    ></textarea>
                  </div>
                </div>
              ))}
              <h4 className="text-center mb-5 text-green-100 cursor-pointer underline" onClick={() => handleAddFundingUsage()}>
                + Add funding usage
              </h4>
            </>
          )}
          <div className="flex gap-4 justify-end">
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("financing")}>
              {isEditMode.financing ? "Cancel" : "Edit"}
            </Button>
            {isEditMode.financing && (
              <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={() => handleUpdateDetails("financing")}>
                {isUpdating ? <Loader isLoading={true} /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="general-card" ref={Documents} data-section="Documents">
        <div className="general-card-section">
          <h2 className="text-[14px]">Documents</h2>
          <p className="text-[11px] ">Project Files & Agreements</p>
        </div>
        <div className="vertical-line"></div>
        <div className="general-card-section text-[11px]">
          <div className="overflow-auto sm:overflow-hidden">
            <div className="sm:w-full w-[500px]">
              <div className="grid grid-cols-3 gap-8 text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
                <span className="text-start">Type</span>
                <span className="text-start">File</span>
                <span className="text-start">Status</span>
              </div>
              <div>
                {documentRequestList.length === 0 && <p className="flex items-center justify-center m-[20px]">No documents requested</p>}
                {documentRequestList.map((item: any, index: number) => (
                  <div key={item.id} className="w-full grid grid-cols-3 gap-8 items-center py-3 border-b border-b-monochrome-10">
                    <div className="flex gap-2 text-black sm:gap-4 text-sm items-start font-medium truncate">
                      <span className="body-medium flex gap-2 items-center truncate">{item.documentId.documentName.replace(/_/g, " ")}</span>
                    </div>
                    {(item.status === "Not Submitted" || item.status === "Rejected") && (
                      <div className="flex justify-start">
                        <Button
                          primary={true}
                          rounded={true}
                          classNames="w-full !justify-start !px-4"
                          disabled={isLoading}
                          onClick={() => inputFile.current[index].click()}
                        >
                          <IcFile /> Upload file
                        </Button>
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e: any) => handleFileUpload(e, item._id)}
                          ref={(el) => (inputFile.current[index] = el)}
                        />
                      </div>
                    )}
                    {item.status === "Pending" && (
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <IcDoc />
                          <Link to={item?.documentPath} target="_blank" className="underline font-medium text-xs">
                            {item?.documentName}
                          </Link>
                        </div>
                        <div className="bg-monochrome-5 rounded-lg p-1 mr-4 cursor-pointer" onClick={() => removeDocument(item._id)}>
                          <IcCross />
                        </div>
                      </div>
                    )}
                    <div className="flex gap-4 items-center">
                      <div
                        className={`w-fit bg-status-10 !text-xs rounded-lg py-0 px-3 caption-medium flex justify-center gap-2 h-[33px] items-center ${
                          item.status === "Not Submitted"
                            ? "project-status-notSubmitted"
                            : item.status === "Rejected"
                            ? "project-status-issued"
                            : item.status === "Pending"
                            ? "project-status-pending"
                            : "project-status-approved"
                        }`}
                      >
                        <div className="w-1.5 h-1.5 rounded-full bg-status-10"></div>
                        {item.status}
                      </div>
                      {item.rejectionReason && item.rejectionReason}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
