import React from 'react'

const IcRedirect = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.82841 3.67134L3.17163 9.32812" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.42557 3.83158L8.82828 3.67115L8.66821 8.07422" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default IcRedirect