import React, { useState, useEffect, useRef } from "react";
import "./SingleRangeSlider.css";

interface SingleRangePickerProps {
  onChange?: (value: number) => void;
  maxRange?: number;
  minRange?: number;
  value?: number;
}

const SingleRangePicker = (props: SingleRangePickerProps) => {
  const { onChange, maxRange = 100, minRange = 0, value=0 } = props;

  const [price, setPrice] = useState<number>(minRange);
  const thumbPosition = ((price - minRange) / (maxRange - minRange)) * 100;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setPrice(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const valueBubbleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPrice(value);
  }, [value]);

  useEffect(() => {
    if (valueBubbleRef.current) {
      valueBubbleRef.current.style.left = `${thumbPosition}%`;
      valueBubbleRef.current.style.transform = `translate(-50%, -100%)`;
    }
  }, [thumbPosition]);

  return (
    <div className="w-full flex items-center">
      <span className="mr-4">{minRange}%</span>
      <div className="relative w-full z-0">
        <input
          type="range"
          step="1"
          min={minRange}
          max={maxRange}
          value={price}
          onChange={handleChange}
          className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
        />
        <div className="relative z-10 h-[6px]">
          <div className="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"></div>
          <div className="absolute z-20 top-0 bottom-0 rounded-md bg-[#00C798]" style={{ width: `${thumbPosition}%` }}></div>
          <div className="h-[12px] w-[12px] absolute z-30 bg-white border border-[#00C798] rounded-full -mt-1" style={{ left: `${thumbPosition}%` }}></div>
        </div>
        <div className="value-bubble" ref={valueBubbleRef}>
          {price} %
        </div>
      </div>
      <span className="ml-4">{maxRange}%</span>
    </div>
  );
};

export default SingleRangePicker;
