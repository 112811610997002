import { IcFolder } from "@/assets/Icons";
import IcCross from "@/assets/Icons/ic_cross";
import Button from "@/components/UI/Button";
import { formatBytes } from "@/constants/extensions";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";

type Props = {
    currentStep: number,
    setCurrentStep: (value: number) => void;
    setFormDetails: (value: any) => void;
    formDetails: any,
    handleSubmit: () => void
}

const validationSchema = z.object({
    dataRoomLink: z.string().optional(),
    files: z.array(
        z.object({
            name: z.string(),
            size: z.number(),
            type: z.string(),
            lastModified: z.number(),
        })
    ).optional()
});

const SupportingDocuments = ({ currentStep, setCurrentStep, setFormDetails, formDetails }: Props) => {
    const [errors, setErrors] = useState<any>({});
    const [selectedFiles, setSelectedfiles] = useState<any>(formDetails.files || []);
    const inputPicker = useRef<HTMLInputElement | null>(null);

    const openFilePicker = () => {
        if (inputPicker.current) {
            inputPicker.current.click();
        }
    };

    const handleImageChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const files = inputPicker.current?.files;
        if (files) {
            let filesArray: any = [];
            Array.from(files).forEach(file => {
                filesArray.push(file);
            });

            if (selectedFiles.length + filesArray.length > 10) {
                toast.error("You can only upload a maximum of 10 documents.");
            } else {
                setSelectedfiles([...selectedFiles, ...filesArray]);
            }
        }
    };

    const removeFile = (index: number) => {
        setSelectedfiles(selectedFiles.filter((_: any, i: number) => i !== index));
    };

    useEffect(() => {
        setFormDetails((prev: any) => ({ ...prev, ['files']: selectedFiles }))
    }, [selectedFiles]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormDetails((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));

        const fieldValidationResult = validationSchema.pick({ [name]: true }).safeParse({ [name]: value });
        if (!fieldValidationResult.success) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: fieldValidationResult.error.flatten().fieldErrors[name][0],
            }));
        } else {
            setErrors((prevErrors: any) => {
                const newErrors = { ...prevErrors } as any;
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    const handleNext = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const result = validationSchema.safeParse(formDetails);

        if (!result.success) {
            setErrors(result.error.flatten().fieldErrors);
            toast.error("Please correct the errors before submitting.");
            return;
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <>
            <h4>(Optional) Please share your data room or any other resources </h4>
            <p className='text-monochrome-30 mt-2 body-regular'>This helps us reduce the processing time</p>
            <form onSubmit={handleNext} className="body-small-regular">
                <div className="grid grid-cols-1 gap-2 mt-6">
                    <label>Additional Links</label>
                    <textarea placeholder="Add relevant links" name="dataRoomLink" className="border border-monochrome-20 rounded-lg" rows={6} value={formDetails?.dataRoomLink} onChange={(e) => handleChange(e)}></textarea>
                    {errors.dataRoomLink && <p className="text-red-500">{errors.dataRoomLink}</p>}
                </div>

                <div className="flex flex-column gap-6 mt-6">
                    <div className="flex flex-column">
                        <label>Upload Files</label>
                        <input type="file" className="hidden" ref={inputPicker} onInput={handleImageChange} multiple />
                        <Button primary rounded classNames="bg-transparent py-3 w-[150px]" onClick={openFilePicker}>
                            <IcFolder />
                            Upload file
                        </Button>
                        <p className="text-monochrome-30 mt-2">Upload a maximum of 10 documents</p>
                        {errors.files && <p className="text-red-500 mt-2">{errors.files}</p>}
                    </div>
                    <div className="flex flex-column">
                        <label>Attached Files</label>
                        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            {
                                selectedFiles.map((item: any, index: number) => (
                                    <div key={'file-' + index} className="border border-monochrome-20 rounded-lg flex gap-2 flex-column px-4 py-2 pr-8 relative">
                                        <span>{item.name}</span>
                                        <span className="text-monochrome-30">{formatBytes(item.size)}</span>
                                        <div className="bg-monochrome-5 cursor-pointer w-[24px] aspect-square rounded absolute top-2 right-2 flex items-center justify-center">
                                            <IcCross onClick={() => removeFile(index)} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='flex gap-4'>
                    <Button primary rounded classNames="mt-6" onClick={() => { setCurrentStep(currentStep - 1) }}>Back</Button>
                    <Button secondary rounded type="submit" classNames="mt-6">Next</Button>
                </div>
            </form>
        </>
    );
}

export default SupportingDocuments;
