import { numberWithCommas } from "@/constants/extensions";

const InvestorPurchasingItems = ({ item }: any) => {
    return (
        <div key={item._id} className="grid grid-cols-4 py-3 border-b border-b-monochrome-10">
            <div className="z-10 flex gap-2 text-black sm:gap-4 text-sm items-start font-medium">
                <span className='body-medium flex gap-2 items-center'>
                    {item.investorId}
                </span>
            </div>
            <span className="text-monochrome-60 body-regular text-start my-auto">{numberWithCommas(item.price ?? 0)} USD</span>
            <span className="text-monochrome-60 body-regular text-start my-auto">{numberWithCommas(item.amount ?? 0)} USD</span>
            <div className="text-monochrome-60 body-regular flex justify-end items-start my-auto">
                <div className={`bg-status-10 rounded-lg py-1 px-3 caption-medium flex justify-end items-center ml-auto project-status-${item.status.toLowerCase()}`}>
                    <div className={`rounded-full w-2 h-2 mr-2`}></div>
                    {item.status}
                </div>
            </div>
        </div>
    );
};

export default InvestorPurchasingItems;
