import React, { useState, useMemo } from 'react';
import { useQuery } from "@tanstack/react-query";
import Loader from "@components/Loader";
import useAuth from "@/hooks/useAuth";
import TransactionItem from "../../components/Transactions/TransactionItem";
import Button from "@/components/UI/Button";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import { IcNextPage, IcPreviousPage, IcSearch } from '@/assets';
import IcFilter from '@/assets/ic_filter';
import { fetchTransactions } from '@/services/api';

const sortingOptions = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
];

const perPageItems = [10, 25, 50, 100];

const TransactionsPage: React.FC = () => {
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<string>("newest");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { companyId } = useAuth();

  const { data: transactions = [], isLoading } = useQuery({
    queryKey: ["getTransactions", companyId],
    queryFn: () => fetchTransactions(companyId),
    enabled: !!companyId,
    select: (data) => data.data
  });

  const REWARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PNMO";

  const getTransactionType = (transaction: any): string => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from?.toLowerCase() === REWARD_FROM_ADDRESS) {
      return "reward transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any): string => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "reward transaction" ? "cards reward-transaction" : "cards";
  };

  const sortedTransactions = useMemo(() => {
    return transactions.sort((a: any, b: any) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    });
  }, [transactions, sortOrder]);

  const totalPages = useMemo(() => {
    return Math.ceil(sortedTransactions.length / perPageCount);
  }, [sortedTransactions.length, perPageCount]);

  const displayedTransactions = useMemo(() => {
    const startIndex = (currentPage - 1) * perPageCount;
    return sortedTransactions.slice(startIndex, startIndex + perPageCount);
  }, [sortedTransactions, currentPage, perPageCount]);

  const filteredTransactions = useMemo(() => {
    const searchText = searchQuery.toLowerCase();
    return displayedTransactions.filter((transaction: any) => {
      return (
        transaction.date?.toLowerCase().includes(searchText) ||
        transaction.transactionType?.toLowerCase().includes(searchText) ||
        transaction.tokenSymbol?.toLowerCase().includes(searchText) ||
        transaction.time?.toLowerCase().includes(searchText) ||
        transaction.amount?.toString().toLowerCase().includes(searchText)
      );
    });
  }, [displayedTransactions, searchQuery]);

  return (
    <div>
      <div className="mb-4 flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-4">
        <div className="relative w-full md:w-[250px]">
          <input 
            type="text" 
            placeholder="Search transactions..." 
            className="w-full md:w-[250px] h-[40px] !text-[11px] border-none m-0 pr-[35px] rounded-lg"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="absolute top-[13px] right-3 text-monochrome-20">
            <IcSearch />
          </div>
        </div>

        <div className="flex gap-4">
          <Dropdown
            menuBoxMarginTop={5}
            className="w-[200px] h-[40px] text-[12px] rounded"
            onChange={(selectedValue: any) => {
              setSortOrder(selectedValue.value);
              setCurrentPage(1);
            }}
            options={sortingOptions}
            defaultValue={sortingOptions[0].value} // Default to "Newest"
          />

          {/* <Button primary rounded classNames="px-4 w-[180px] h-[40px] bg-white border-none text-monochrome-20">
            Filters
            <IcFilter />
          </Button> */}
        </div>
      </div>

      <div className="bg-white rounded-lg p-4">
        <div className="overflow-x-auto xl:overflow-hidden">
          <div className="xl:relative xl:h-[calc(100vh-180px)] w-[230%] xl:w-full">
            <div className="grid grid-cols-5 body-small-regular text-monochrome-30 py-2 border-b border-monochrome-10">
              <span>Date</span>
              <span>Type</span>
              <span>Symbol</span>
              <span>Time</span>
              <span>Amount</span>
            </div>
            <div className="overflow-y-auto max-h-[calc(100vh-360px)]">
              {
                isLoading ? (
                  <div className="center min-h-20">
                    <Loader isLoading={true} />
                  </div>
                ) : (
                  filteredTransactions.length === 0 ? (
                    <div className="center min-h-40">
                      <h6 className="font-normal">No transactions available.</h6>
                    </div>
                  ) : (
                    filteredTransactions.map((transaction: any, index: number) => (
                      <TransactionItem
                        key={index}
                        item={transaction}
                        onClick={() => window.open(`${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transaction.hash}`, '_blank')}
                      />
                    ))
                  )
                )
              }
            </div>
            {/* <div className="xl:absolute xl:bottom-0 w-full flex justify-between pt-[16px] text-[14px]">
              <div className="flex gap-3 items-center">
                <ul className="flex">
                  {perPageItems.map((item: number, index: number) => (
                    <li
                      key={`page-items-${index}`}
                      onClick={() => {
                        setPerPageCount(item);
                        setCurrentPage(1); // Reset current page on per page count change
                      }}
                      className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer bg-monochrome-5 ${
                        item === perPageCount ? 'border-green-100 bg-status-approved text-green-100' : 'border-monochrome-10 text-monochrome-30'
                      }`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <span className="text-monochrome-20 body-small-regular">Items per page</span>
              </div>
              <div className="flex items-center gap-2 body-small-regular">
                <span className="text-monochrome-20">Page</span>
                <input
                  className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]"
                  type="text"
                  value={currentPage}
                  min={1}
                  onChange={(e) => setCurrentPage(Number(e.target.value))}
                />
                <span className="text-monochrome-20">of {totalPages}</span>
                <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                  <button
                    className="p-2 bg-monochrome-5 rounded-l-lg"
                    onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                    disabled={currentPage === 1}
                  >
                    <IcPreviousPage />
                  </button>
                  <button
                    className="p-2 bg-monochrome-5 rounded-r-lg"
                    onClick={() =>
                      setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)
                    }
                    disabled={currentPage === totalPages}
                  >
                    <IcNextPage />
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;
