import Web3 from "web3";

const TransactionItem = ({ item, id, onClick }: any) => {
  return (
    <div className="grid grid-cols-6 py-4 border-b border-monochrome-10 body-regular cursor-pointer hover:bg-gray-100 text-monochrome-60 " key={id} onClick={onClick}>
      <span className="text-monochrome-100 col-span-2">{item.date}</span>
      <span className="capitalize col-span-2">{item.transactionType}</span>
      <span>{item.tokenSymbol}</span>
      <span className={`text-right body-medium ${item.tokenAmount > 0 ? "text-green-100" : "text-danger-100"}`}>
        {item.tokenAmount > 0 ? '+' : '-'}{ Web3.utils.fromWei(item.tokenAmount.toString(), 'ether') }
      </span>
    </div>
  );
};

export default TransactionItem;
