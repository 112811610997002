import Button from "@/components/UI/Button";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import { revenueGeneratingAssetArray } from "@/constants/getCapitalConstants";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Switch from "react-switch";
import { z } from "zod";
import ReactFlagsSelect from "react-flags-select";

interface Props {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  setFormDetails: (value: any) => void;
  formDetails: any;
  handleSubmit: () => void;
}

const formatNumberWithCommas = (value: string) => {
  const num = value.replace(/\D/g, ""); // Remove non-digit characters
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
};

const removeCommas = (value: string) => {
  return value.replace(/,/g, ""); // Remove commas
};

const RevenueBreakdownStep = ({ currentStep, setCurrentStep, setFormDetails, formDetails }: Props) => {
  const validationSchema = z.object({
    assetType: z.string().min(1, "Revenue generating asset is required"),
    energyCapacity: z.string().min(1, "Energy capacity is required"),
    hasRevenueGeneratingAssets: z.string().min(1, "Is asset generating revenue is required"),
    assetLocation: z.string().min(1, "Location is required"),
    spvLocation: z.string().min(1, "SPV location is required"),
  });

  const [errors, setErrors] = useState<any>({});
  const todayDate: any = new Date().toISOString().split("T")[0];

  const [typeOfRevenue, setTypeOfRevenue] = useState(
    formDetails.typeOfRevenue.length > 0
      ? formDetails.typeOfRevenue
      : [
          {
            name: "Energy Sales PPA",
            value: "PPA",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "Energy Sales Grid",
            value: "GRID",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "RECs",
            value: "RECS",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "Carbon Credits",
            value: "CARBONCREDITS",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "Rental Fees",
            value: "RENTAL",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "Sales",
            value: "SALES",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
          {
            name: "",
            value: "OTHER",
            status: false,
            projectedRevenue: "",
            yearlyOrMonthly: "",
          },
        ]
  );

  const handleChange = (field: string, value: string) => {
    setFormDetails((prevFormData: any) => ({
      ...prevFormData,
      [field]: value,
    }));

    const fieldValidationResult = validationSchema.pick({ [field]: true }).safeParse({ [field]: value });
    if (!fieldValidationResult.success) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [field]: fieldValidationResult.error.flatten().fieldErrors[field][0],
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors } as any;
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleTypeOfRevenueChange = (index: number, field: string, value: any) => {
    if (field === "projectedRevenue") {
      value = formatNumberWithCommas(value);
    }

    if (field === "status" && value === false) {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index].status = value;
        newTypeOfRevenue[index].projectedRevenue = "";
        newTypeOfRevenue[index].yearlyOrMonthly = "";
        if (index === newTypeOfRevenue.length - 1) {
          newTypeOfRevenue[index].name = "";
        }
        return newTypeOfRevenue;
      });
    } else {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index][field] = value;
        return newTypeOfRevenue;
      });
    }
  };

  const handleNext = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Convert revenue values to regular number format before validation
    const formattedDetails = {
      ...formDetails,
      energyCapacity: removeCommas(formDetails.energyCapacity),
      typeOfRevenue: typeOfRevenue.map((item: any) => ({
        ...item,
        projectedRevenue: removeCommas(item.projectedRevenue),
      })),
    };

    const result = validationSchema.safeParse(formattedDetails);

    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      toast.error("Please correct the errors before submitting.");
      return;
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    setFormDetails((prev: any) => ({
      ...prev,
      typeOfRevenue: typeOfRevenue.map((item: any) => ({
        ...item,
        projectedRevenue: removeCommas(item.projectedRevenue),
      })),
    }));
  }, [typeOfRevenue]);

  return (
    <>
      <h4>Projected revenues of your asset</h4>
      <p className="text-monochrome-30 mt-2 body-regular">Asset Revenue Breakdown</p>

      <form onSubmit={handleNext} className="body-small-regular">
        <div className="grid xl:grid-cols-2 gap-6 mt-6">
          <div>
            <label>Revenue generating asset for payback</label>
            <Dropdown
              menuBoxMarginTop={5}
              value={formDetails.assetType}
              className={`mt-2 px-0 rounded-lg border border-monochrome-10 h-[42px]`}
              onChange={(selectedValue: any) => {
                handleChange("assetType", selectedValue.value);
              }}
              options={revenueGeneratingAssetArray}
            />
            {errors.assetType && <p className="text-red-500 mt-1">{errors.assetType}</p>}
          </div>

          <div className="relative">
            <label>{formDetails.assetType === "BATTERY" ? "Energy Capacity ( kWh )" : "Power Capacity ( kW )"}</label>
            <input
              type="text"
              name="energyCapacity"
              className={`h-[42px] mt-2 w-full pl-3 border !border-monochrome-10 !rounded-lg`}
              value={formatNumberWithCommas(formDetails.energyCapacity)}
              onChange={(e) => handleChange("energyCapacity", e.target.value.replace(/,/g, ""))}
              readOnly={false} // Ensure it's not set to readOnly if you need input functionality
            />
            <p className="absolute right-4 top-[55%]  text-monochrome-60">{formDetails.assetType === "BATTERY" ? "kWh" : "kW"}</p>
            {errors.energyCapacity && <p className="text-red-500 mt-1">{errors.energyCapacity}</p>}
          </div>

          <div>
            <label>Is this asset already generating revenue?</label>
            <Dropdown
              menuBoxMarginTop={5}
              value={formDetails.hasRevenueGeneratingAssets}
              className={`mt-2 px-0 rounded-lg border border-monochrome-10 h-[42px]`}
              onChange={(selectedValue: any) => {
                handleChange("hasRevenueGeneratingAssets", selectedValue.value);
              }}
              options={[
                { value: "1", label: "Yes" },
                { value: "0", label: "No" },
              ]}
            />
            {errors.hasRevenueGeneratingAssets && <p className="text-red-500 mt-1">{errors.hasRevenueGeneratingAssets}</p>}
          </div>
          {formDetails.hasRevenueGeneratingAssets !== "" && (
            <div>
              <label>{formDetails.hasRevenueGeneratingAssets === "1" ? "Since when is it generating revenue?" : "When will it generate revenue?"}</label>
              <input
                type="date"
                name="commercialOperationDate"
                className={`mt-2 w-full px-2 border !border-monochrome-10 !rounded-lg h-[42px]`}
                value={formDetails.commercialOperationDate}
                onChange={(e) => handleChange("commercialOperationDate", e.target.value)}
                max={formDetails.hasRevenueGeneratingAssets === "1" ? todayDate : undefined}
                min={formDetails.hasRevenueGeneratingAssets !== "1" ? todayDate : undefined}
              />
              {errors.commercialOperationDate && <p className="text-red-500 mt-1">{errors.commercialOperationDate}</p>}
            </div>
          )}
        </div>

        <div className="my-6">
          <div className="hidden xl:block">
            <div className="grid grid-cols-3 mb-4">
              <span>Type of revenue</span>
              <span>Estimated Revenue</span>
              <span>Calculation period</span>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 xl:grid-cols-1 gap-4">
            {typeOfRevenue.map((item: any, index: any) => (
              <div className="grid xl:grid-cols-3 gap-3 items-center p-3 xl:p-0 rounded-lg bg-[#FCFCFC] xl:bg-white" key={index}>
                <div className="flex items-center gap-2 text-monochrome-60">
                  <Switch
                    offColor="#fff"
                    onColor="#00cc9c"
                    activeBoxShadow={""}
                    offHandleColor={"#9E9E9E"}
                    onHandleColor={"#fff"}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={18}
                    borderRadius={9}
                    className={`overflow-hidden border-2 rounded-full border-monochrome-10 border-green-500`}
                    onChange={() => handleTypeOfRevenueChange(index, "status", !item.status)}
                    checked={item.status}
                  />

                  {index !== typeOfRevenue.length - 1 ? (
                    <p>{item.name}</p>
                  ) : (
                    <div>
                      <input
                        type="text"
                        tabIndex={0}
                        name="capitalNeeded"
                        disabled={!item.status}
                        className={`border-monochrome-10 !rounded-lg`}
                        value={item.name}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^A-Za-z]/gi, "");
                          handleTypeOfRevenueChange(index, "name", value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="relative">
                  <label className="xl:hidden">Projected revenue?</label>
                  <input
                    type="text"
                    name="capitalNeeded"
                    className={`mt-2 xl:mt-0 w-full pl-6 ${!item.status ? "border-monochrome-5" : "border-monochrome-10"} !rounded-lg`}
                    disabled={!item.status}
                    value={item.projectedRevenue}
                    onChange={(e) => handleTypeOfRevenueChange(index, "projectedRevenue", e.target.value)}
                  />
                  <p className={`absolute bottom-2 left-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>$</p>
                  <p className={`absolute bottom-2 right-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>USD</p>
                </div>
                <div>
                  <label className="xl:hidden">Projected yearly/monthly revenue?</label>
                  <Dropdown
                    menuBoxMarginTop={5}
                    value={item.yearlyOrMonthly}
                    className={`mt-2 xl:mt-0 px-0 rounded-lg border border-monochrome-10 h-[42px]`}
                    onChange={(val) => handleTypeOfRevenueChange(index, "yearlyOrMonthly", val.value)}
                    options={[
                      { value: "0", label: "Per Month" },
                      { value: "1", label: "Per Year" },
                    ]}
                    disabled={!item.status}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="grid xl:grid-cols-2 gap-6">
          <div>
            <label>Physical location of revenue-generating asset </label>
            <ReactFlagsSelect
              searchable={true}
              searchPlaceholder="Search location"
              placeholder="Select location"
              className={`mt-2 px-0 body-small-regular rounded-lg`}
              selectButtonClassName={"body-small-regular"}
              optionsSize={12}
              selectedSize={12}
              selected={formDetails.assetLocation}
              onSelect={(code) => {
                handleChange("assetLocation", code);
              }}
            />
            {errors.assetLocation && <p className="text-red-500 mt-1">{errors.assetLocation}</p>}
          </div>
          <div>
            <label>SPV Location of revenue-generating asset (If existent) </label>
            <ReactFlagsSelect
              searchable={true}
              searchPlaceholder="Search location"
              placeholder="Select location"
              className={`mt-2 px-0 body-small-regular rounded-lg`}
              selectButtonClassName={"body-small-regular"}
              optionsSize={12}
              selectedSize={12}
              selected={formDetails.spvLocation}
              onSelect={(code) => {
                handleChange("spvLocation", code);
              }}
            />
            {errors.spvLocation && <p className="text-red-500 mt-1">{errors.spvLocation}</p>}
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            primary
            rounded
            classNames="mt-6"
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            Back
          </Button>
          <Button secondary rounded type="submit" classNames="mt-6">
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default RevenueBreakdownStep;
