import React from 'react'

const IcFile = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2386_5313)">
                <path d="M10.125 4.91C9.915 4.91 9.75 4.745 9.75 4.535V1.625C9.75 1.415 9.585 1.25 9.375 1.25L2.625 1.25C2.415 1.25 2.25 1.415 2.25 1.625V3.125C2.25 3.335 2.085 3.5 1.875 3.5C1.665 3.5 1.5 3.335 1.5 3.125V1.625C1.5 1.0025 2.0025 0.5 2.625 0.5L9.375 0.5C9.9975 0.5 10.5 1.0025 10.5 1.625V4.535C10.5 4.745 10.335 4.91 10.125 4.91Z" fill="black" />
                <path d="M10.875 12.5H1.125C0.5025 12.5 0 11.9975 0 11.375L0 3.875C0 3.2525 0.5025 2.75 1.125 2.75H4.6875C4.8075 2.75 4.92 2.8025 4.9875 2.9L6 4.25H10.875C11.4975 4.25 12 4.7525 12 5.375V11.375C12 11.9975 11.4975 12.5 10.875 12.5ZM1.125 3.5C0.915 3.5 0.75 3.665 0.75 3.875L0.75 11.375C0.75 11.585 0.915 11.75 1.125 11.75H10.875C11.085 11.75 11.25 11.585 11.25 11.375V5.375C11.25 5.165 11.085 5 10.875 5H5.8125C5.75408 5.00122 5.69623 4.98821 5.64396 4.96207C5.5917 4.93594 5.54658 4.89747 5.5125 4.85L4.5 3.5H1.125Z" fill="black" />
                <path d="M4.125 10.25H2.625C2.415 10.25 2.25 10.085 2.25 9.875C2.25 9.665 2.415 9.5 2.625 9.5H4.125C4.335 9.5 4.5 9.665 4.5 9.875C4.5 10.085 4.335 10.25 4.125 10.25Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_2386_5313">
                    <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IcFile