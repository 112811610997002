import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { AuthContext } from "@services/AuthContext";
import Loader from "./Loader";
import { IcTotalFinancingReceived, IcWithdrowal } from "@/assets/Icons";
import Button from "./UI/Button";
import WithdrawWallet from "./WithdrawWallet";

const PenomoRewards = () => {
  const { provider } = useContext(AuthContext);
  const [balance, setBalance] = useState("0.00");
  const [isLoading, setIsLoading] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  // useEffect(() => {
  //   // if (!provider) {
  //   //   console.error("Web3 provider is not available");
  //   //   setIsLoading(false);
  //   //   return;
  //   // }

  //   // const web3 = new Web3(provider);

  //   // const tokenAddress = "0xF1B3f692a9faB2703637F293Bc2c34a87B532ae4"; // Your contract address
  //   // const tokenABI = [
  //   //   {
  //   //     inputs: [{ internalType: "address", name: "account", type: "address" }],
  //   //     name: "balanceOf",
  //   //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //   //     stateMutability: "view",
  //   //     type: "function",
  //   //   },
  //   // ];

  //   // const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

  //   // const fetchTokenBalance = async () => {
  //   //   try {
  //   //     const accounts = await web3.eth.getAccounts();
  //   //     if (accounts.length === 0) {
  //   //       console.error("No account found.");
  //   //       setIsLoading(false);
  //   //       return;
  //   //     }

  //   //     const walletAddress = accounts[0];
  //   //     const balanceInWei = await tokenContract.methods.balanceOf(walletAddress).call();
  //   //     const balanceInPenomo = web3.utils.fromWei(balanceInWei, "ether"); // Assuming PENOMO token uses 18 decimal places
  //   //     setBalance(balanceInPenomo);
  //   //   } catch (error) {
  //   //     console.error("Error fetching token balance:", error);
  //   //   } finally {
  //   //     setIsLoading(false);
  //   //   }
  //   // };
  // //   fetchTokenBalance();
  // // }, [provider]);

  //   const fetchTokenBalance = async () => {
  //     try {
  //       // const accounts = await web3.eth.getAccounts();
  //       // if (accounts.length === 0) {
  //       //   console.error("No account found.");
  //       //   setIsLoading(false);
  //       //   return;
  //       // }

  //       // const walletAddress = accounts[0];
  //       // const balanceInWei = await tokenContract.methods.balanceOf(walletAddress).call();
  //       // const balanceInPenomo = web3.utils.fromWei(balanceInWei, "ether"); // Assuming PENOMO token uses 18 decimal places
  //       const balanceInPenomo = 0;
  //       setBalance(balanceInPenomo);
  //     } catch (error) {
  //       console.error("Error fetching token balance:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchTokenBalance();
  // }, [provider]);

  return (
    <div className="bg-white p-4 grow flex flex-column rounded-lg gap-4">
      <div className="flex flex-row gap-4 items-start">
        <IcTotalFinancingReceived />
        <div className="flex flex-column gap-2">
          <span className="body-small-regular text-monochrome-30">penomo Rewards</span>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <h2 className="font-medium text-3xl">
              {balance} <span className="text-monochrome-30 leading-6 text-base">PMNO</span>
            </h2>
          )}
        </div>
      </div>
      <Button primary rounded classNames="" onClick={() => setShowWithdrawModal(true)}>
        <IcWithdrowal />
        Withdraw
      </Button>
      {/* {showWithdrawModal && <WithdrawWallet show={showWithdrawModal} handleClose={() => setShowWithdrawModal(false)} />} */}
    </div>
  );
};

export default PenomoRewards;
