import React from 'react'

const IcDoc = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3273 12.2148H6.4082" stroke="black" strokeLinecap="square" strokeLinejoin="round"/>
    <path d="M8.84293 9.37793H6.40723" stroke="black" strokeLinecap="square" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6687 2.0625H3.29395V15.9375H14.3454V6.0512L10.6687 2.0625Z" stroke="black" strokeLinecap="square"/>
    <path d="M10.3262 2.47852V6.48758H14.0033" stroke="black" strokeLinecap="square"/>
    </svg>
    
  )
}

export default IcDoc