import { ENDPOINT } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { http } from "@/services/http";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import Button from "../UI/Button";
import Loader from "../Loader";
import { IcCopy, IcLock } from "@/assets/Icons";
import Dropdown from "../UI/Dropdown/Dropdown";
import KybVerificationBanner from "./KybVerificationBanner";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fetchCompany, fetchUser } from "@/services/api";
import useStore from "@/store/useStore";

// Zod schema for form validation
const companyDataSchema = z.object({
    companyTicker: z.string().min(1, "Company Ticker is required"),
    // walletAddress: z.string().min(1, "Wallet Address is required"),
    // members: z.array(z.string()).optional(), // Members are now optional
});

const BasicCompanyDetails = ({ updateCompanyFormStatus }) => {
    const { setCompany, companyData } = useStore();
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [formData, setFormData] = useState({
        companyTicker: "",
        walletAddress: "",
    });
    const [companyFormIncomplete, setCompanyFormIncomplete] = useState(true);

    const [initialFormData, setInitialFormData] = useState({});
    const [users, setUsers] = useState<any>([]); // To store all users for member selection

    const [errors, setErrors] = useState<any>({});

    const [isEditMode, setIsEditMode] = useState(false);
    const { userId, companyId } = useAuth();
    const [isVerified, setIsVerified] = useState(true);
    const showCopiedToast = (message: string) => {
        toast.success(message);
    }
    useEffect(() => {
        Promise.all([fetchCompany(companyId), fetchUser(userId)])
            .then(([companyResponse, userResponse]) => {
                const companyDetails = companyResponse.data;
                const userDetails = userResponse.data;

                const newFormData = {
                    companyTicker: companyDetails.companyTicker || "",
                    walletAddress: userDetails.walletDetails.web3authWalletAddress || "",
                    members: companyDetails.boardMembers || [],
                };
                setFormData(newFormData);
                setInitialFormData(newFormData);
                // setUsers(Array.isArray(userResponse.data) ? userResponse.data : []); // Ensure users is always an array
                setIsVerified(companyResponse.data.kybStatus === "Verified");
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error("Failed to fetch data. Try again later.");
            });
    }, []);

    useEffect(() => {
        const allFieldsFilled =
          formData.companyTicker.trim() !== "" &&
          formData.walletAddress.trim() !== "";
        updateCompanyFormStatus(!allFieldsFilled);
      }, [formData, updateCompanyFormStatus]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "members") {
            const selectedMembers = Array.from(e.target.selectedOptions, (option) => option.value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                members: selectedMembers,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }
    };

    const validateForm = () => {
        const result = companyDataSchema.safeParse(formData);
        if (!result.success) {
            console.log(result.error.errors);

            const newErrors = {};
            result.error.errors.forEach((error) => {
                newErrors[error.path[0]] = error.message;
            });
            setErrors(newErrors);
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!isEditMode) return;
        if (!validateForm()) {
            toast.error("Please correct the errors before submitting.");
            return;
        }
        setIsSubmittingForm(true);
        try {
            const response = await http.patch(`${ENDPOINT.GET_COMPANY}/${companyId}`, formData);
            console.log("Response After Submit: ", response);
            setCompany(response.data.data);
            setIsSubmittingForm(false);
            toast.success("Company Details Updated Successfully");
            setInitialFormData(formData);
            setIsEditMode(false);
        } catch (error) {
            setIsSubmittingForm(false);
            console.error("Error updating company details:", error);
            toast.error("Error updating company details. Please check the console for more details.");
        }
    };

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-col sm:flex-row bg-white p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-10'>
                <div className='flex flex-col gap-2 sm:w-4/12 pb-4 md:pb-0'>
                    <h6>Basic Company Details</h6>
                    <p className='text-monochrome-20 body-small-regular'>Manage essential company information</p>
                </div>
                <form className="flex flex-col body-small-regular gap-6 w-full sm:pl-8 pt-4 lg:pt-0 sm:pr-6 sm:w-8/12" onSubmit={handleSubmit}>
                    <div className="relative flex flex-col gap-2">
                        <label>Company Ticker</label>
                        <input type="text" name="companyTicker" placeholder="Enter your company ticker here" disabled={!isEditMode} className={`w-full px-0 ${isEditMode ? 'border border-monochrome-10 rounded px-2' : 'border-none !rounded-none'}`} value={formData.companyTicker} onChange={handleChange} />
                        {errors.companyTicker && <p className="text-red-500">{errors.companyTicker}</p>}
                    </div>
                    <div className="relative flex flex-col gap-2">
                        <label>Wallet Address</label>
                        <div className="flex flex-row items-center border-b">
                            <IcLock className="text-monochrome-30 mr-3" />
                            <input type="text" name="walletAddress" placeholder="Enter your wallet address here" disabled className={`w-full px-0 bg-transparent  !border-none rounded-none`} value={formData.walletAddress} onChange={handleChange} />
                            <CopyToClipboard text={formData.walletAddress} onCopy={() => showCopiedToast('Wallet address copied to clipboard!')}><IcCopy className={'cursor-pointer'} /></CopyToClipboard>
                        </div>
                    </div>
                    <div className="flex gap-4 justify-end">
                        <Button primary rounded type="button" classNames="h-10" onClick={() => setIsEditMode(!isEditMode)} disabled={isSubmittingForm}>
                            {isEditMode ? 'Cancel' : 'Edit'}
                        </Button>
                        {isEditMode && (
                            <Button secondary rounded type="submit" classNames={`h-10 ${isSubmittingForm ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isSubmittingForm}>
                                {isSubmittingForm ? <Loader isLoading={true} /> : 'Save'}
                            </Button>
                        )}
                    </div>
                </form>
            </div>

            {/* <KybVerificationBanner isVerified={isVerified}/> */}
        </div>
    )
}

export default BasicCompanyDetails;
