import { IcWallet } from "@/assets/Icons"

const ProjectStatusBanner = ({ data }: any) => {
    const { status = "" } = { ...data }

    return (
        <section className="col-span-2 bg-white px-[16px] py-[12px] flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 border-[0.5px] rounded-lg shadow-lg">
            <div className="flex flex-wrap grow items-center">
                <div className="flex grow items-center">
                    <IcWallet />
                    <div className="flex flex-column ml-4">
                        <p className="body-small-regular text-monochrome-30">
                            Project Status
                        </p>
                        <h4 className="font-medium text-monochrome-100">
                            {status == "Pending" && "Due diligence pending."}
                            {status == "Accepted" && "Due diligence accepted. You can now list your project."}
                            {status == "Rejected" && "Due diligence rejected. Please contact our support."}
                        </h4>
                    </div>
                </div>
                {status !== "Pending" && status!== "Accepted" && (
                    <div className="flex flex-wrap items-center justify-center gap-[10px]">
                        <div className="flex flex-col items-center justify-center w-[127px] bg-[#F6F6F6] rounded-[10px] py-[5px]">
                            <h3>4</h3>
                            <p className="text-[10px] text-[#8E8E8E]">days</p>
                        </div>
                        <div className="flex flex-col items-center justify-center w-[127px] bg-[#F6F6F6] rounded-[10px] py-[5px]">
                            <h3>7</h3>
                            <p className="text-[10px] text-[#8E8E8E]">hours</p>
                        </div>
                        <div className="flex flex-col items-center justify-center w-[127px] bg-[#F6F6F6] rounded-[10px] py-[5px]">
                            <h3>36</h3>
                            <p className="text-[10px] text-[#8E8E8E]">minutes</p>
                        </div>
                        <div className="flex flex-col items-center justify-center w-[127px] bg-[#F6F6F6] rounded-[10px] py-[5px]">
                            <h3>2</h3>
                            <p className="text-[10px] text-[#8E8E8E]">seconds</p>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default ProjectStatusBanner