const IcAcArrowDown = (props: any) => {
    return (
        <div {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 7L8 10L11 7" stroke="#5B5B5B" strokeWidth="0.75" strokeLinecap="round" />
            </svg>
        </div>
    )
}

export default IcAcArrowDown