import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import { AuthContext } from "@services/AuthContext";
import { classNames } from "@/utils";
import ContactSupportModal from "./ContactSupport/ContactSupport";
import Logout from '@/components/Logout';
import logo from "../assets/penomo_logo.svg";
import "./NavBar.css";
import useAuth from "@/hooks/useAuth";
import { NavBarList } from "@/constants/NavMenu";
import { IcClose, IcHamburger } from "@/assets/menu";

const NavBar = ({ handleLinkClick ,setShowLogoutModal} :any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userId } = useAuth();
  const { authToken } = useContext(AuthContext);


  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  if(isSetupProfilePage){
    return <></>;
  }

  return (
    <>
      <nav className={classNames("z-[30] w-full lg:w-60 bg-white", { hidden: userId == null })}>
        <div className="h-full max-w-screen-xl flex flex-row lg:flex-col lg:justify-between flex-wrap lg:pl-8 py-5 !pt-[20px]">
          <div className="w-full">
            <div className={"w-full flex items-center justify-between lg:mb-10"}>
              <NavLink to={ROUTES.DASHBOARD} className="flex">
                <img src={logo} className="navbar-logo h-6" alt="penomo" />
              </NavLink>
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden"
                aria-controls="navbar-default"
                aria-expanded={isNavOpen}
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                {isNavOpen ? <IcClose className="w-6 h-6" /> : <IcHamburger className="w-6 h-6" />}
              </button>
            </div>

            <div
              className={classNames(
                "fixed inset-0 bg-white z-50 top-[5rem] overflow-auto lg:static lg:bg-transparent lg:z-auto lg:block",
                { hidden: !isNavOpen }
              )}
              id="navbar-default"
            >
              <ul className="lg:mt-0 py-2 lg:pl-0 lg:py-0 flex flex-col w-full gap-y-8 lg:justify-center">
                {userId && !isSetupProfilePage && (
                  <>
                    {NavBarList.map((item, index) => {
                      return (
                        <li key={"menu-" + index}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`pl-4 rounded flex justify-between bg-none`}
                            onClick={(e) => handleLinkClick(e, item.link)}
                          >
                            <div className={"flex justify-center items-center gap-4"}>
                              {pathname.includes(item.link) ? item.iconWhenSelected : item.icon}
                              <span
                                className={`flex my-auto text-xs leading-tight ${pathname.includes(item.link) ? "text-green-100" : "text-monochrome-50"
                                  }`}
                              >
                                {item.title}
                              </span>
                            </div>
                            <p
                              className={`w-1.5 h-5 rounded-l-lg bg-green-100 ${pathname.includes(item.link) ? "block" : "hidden"
                                }`}
                            ></p>
                          </NavLink>
                        </li>
                      );
                    })}
                    <li className="lg:hidden px-4 py-[10px]">
                      <Logout setShowLogoutModal={setShowLogoutModal}/>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className="hidden lg:block pl-4">
            <Logout setShowLogoutModal={setShowLogoutModal}  />
          </div> 
        </div>
      </nav>
    </>
  );
};

export default NavBar;
