import React, { useState } from 'react';
import { numberWithCommas } from "@/constants/extensions";
import { http } from "@/services/http"; // Assuming http service is configured to handle PATCH requests
import toast from "react-hot-toast";
import { ENDPOINT } from '@/constants';
import { useQueryClient } from '@tanstack/react-query';
import { FaCheck, FaPencilAlt } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';

const ProjectItem = ({ item, onUpdateProject, handleClickProject }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showPencil, setShowPencil] = useState(false);
  const [updatedName, setUpdatedName] = useState(item.name);
  const queryClient = useQueryClient();

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedName(e.target.value);
  };

  const handleSave = async () => {
    try {
      if (updatedName !== item.name) {
        const response = await http.patch(`${ENDPOINT.PROJECT}/${item._id}`, { name: updatedName });
        if (response.status === 200 && response.data) {
          toast.success("Project name updated successfully.");
          onUpdateProject(item._id, updatedName); // Trigger callback to update project name in parent component
          queryClient.invalidateQueries('getProjects'); // Invalidate the projects query to refetch the data
        } else {
          toast.error("Failed to update project name.");
        }
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error("Failed to update project name.");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setUpdatedName(item.name);
      setIsEditing(false);
    }
  };

  return (
    <div className="grid grid-cols-5 border-b border-monochrome-10 hover:bg-gray-100">
      <span className="flex gap-2 z-10 text-black sm:gap-4 items-center" onDoubleClick={handleDoubleClick} onMouseEnter={() => setShowPencil(true)} onMouseLeave={() => setShowPencil(false)}>
        {isEditing ? (
          <span className="flex items-center gap-2 py-3">
            <input
              type="text"
              value={updatedName}
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
              autoFocus
              className="border-b border-gray-400 focus:outline-none w-56"
            />
            <FaCheck className={`cursor-pointer hover:text-blue-500`} onClick={handleSave} />
            <GrFormClose size={22} className={`cursor-pointer hover:text-red-500`} onClick={() => setIsEditing(false)} />
          </span>
        ) : (
          <span className='flex body-medium items-center gap-2 py-3 '>
            {/* <img className='h-16' src={item.img} /> */}
            {item.name}
            <FaPencilAlt className={`cursor-pointer ${showPencil ? "block" : "hidden"}`} onClick={handleDoubleClick} />
          </span>
        )}
      </span>
      <span onClick={()=> handleClickProject(item)} className="py-3 cursor-pointer text-monochrome-60 body-regular text-end my-auto">{numberWithCommas(item.receivedAmount ?? 0)} USD</span>
      <span onClick={()=> handleClickProject(item)} className="py-3 cursor-pointer text-monochrome-60 body-regular text-end my-auto">{numberWithCommas(item.fundingGoal)} USD</span>
      <div onClick={()=> handleClickProject(item)} className="py-3 cursor-pointer text-monochrome-60 body-regular flex justify-end items-center gap-2 my-auto">
        <div className="rounded-lg h-2 w-[100px] border border-monochrome-10">
          <div className={`bg-green-100 h-full rounded-lg`} style={{ width: `${item.financed ?? 0}%` }}></div>
        </div>
        <span>{item.financed ?? 0}%</span>
      </div>
      <div onClick={()=> handleClickProject(item)} className="py-3 cursor-pointer text-monochrome-60 caption-medium flex justify-end items-center my-auto">
        <div className={`bg-status-10 rounded-lg py-1 px-3 flex justify-end items-center ml-auto ${item.status ? `project-status-${item.status.toLowerCase()}` : ''}`}>
          <div className={`rounded-full w-2 h-2 mr-2`}></div>
          {item.status ?? 'Unknown'}
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
