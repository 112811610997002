import { ENDPOINT } from "@/constants";
import { http } from "./http";

const fetchUser = (id: string) => http.get(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
const fetchTransactions = (cid: string) => http.get(`${ENDPOINT.TRANSACTIONS}/${cid}`).then((res) => res.data);
const fetchCompany = (cid: string) => http.get(`${ENDPOINT.GET_COMPANY}/${cid}`).then((res) => res.data);
const fetchProjects = (cid: string) => http.get(`${ENDPOINT.GET_COMPANY_PROJECTS}/${cid}`).then((res) => res.data);
const fetchProjectById = (id: string) => http.get(`${ENDPOINT.GET_COMPANY_PROJECTS_BY_ID}/${id}`).then((res) => res.data);
const getInvestorDetails = (id: string) => http.get(`${ENDPOINT.GET_INVESTOR}/${id}`).then((res) => res.data);
const fetchNotificationsByProjectId = (id: string) => http.get(`${ENDPOINT.GET_NOTIFICATIONS_PROJECTS_BY_ID}/${id}`).then((res) => res.data);
const updateProjectDetails = (cid: string, data: any) => http.patch(`${ENDPOINT.PROJECT}/${cid}`, data).then((res) => res.data);
const updateAssetsById = (cid: string, data: any) => http.patch(`${ENDPOINT.ASSET_BY_ID}/${cid}`, data).then((res) => res.data);
const updateBankDetails = (cid: string, data: any) => http.patch(`${ENDPOINT.GET_COMPANY}/${cid}`, data).then((res) => res.data);
const createNewProject = (cid: string, data: any) => http.post(`${ENDPOINT.PROJECT}/new/${cid}`, data, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
}).then((res) => res.data);
const createNewAsset = (cid: string, data: any) => http.post(`${ENDPOINT.ASSET_NEW}/${cid}`, data).then((res) => res.data);

const fetchAllNotifications = (id: string) => http.get(`${ENDPOINT.NOTIFICATION}/${id}`).then((res) => res.data);
const readNotification = (id: string) => http.patch(`${ENDPOINT.READ_NOTIFICATION}/${id}`).then((res) => res.data);
const readAllNotifications = (id: string) => http.patch(`${ENDPOINT.READ_ALL_NOTIFICATIONS}/${id}`).then((res)=> res.data)

const updateProjectDocumentRequest = (projectId:string, documentRequestId: string, data:any) => http.post(`${ENDPOINT.PROJECT}/${projectId}/${documentRequestId}`, data, {
    headers: {
        "Content-Type": "multipart/form-data",
    }
}).then((res) => res.data);
const getProjectRequestedDocuments = (projectId: string) => http.get(`${ENDPOINT.PROJECT}/${projectId}/requested-documents`).then((res) => res.data);
const changeDocumentRequestStatus = (documentRequestId:string) => http.put(`${ENDPOINT.PROJECT}/project-documents/${documentRequestId}`).then((res) => res.data);

export {
    getInvestorDetails,
    updateAssetsById,
    fetchUser,
    fetchProjectById,
    fetchTransactions,
    fetchCompany,
    fetchProjects,
    updateBankDetails,
    createNewProject,
    createNewAsset,
    updateProjectDetails,
    fetchAllNotifications,
    readNotification,
    readAllNotifications,
    fetchNotificationsByProjectId,
    updateProjectDocumentRequest,
    getProjectRequestedDocuments,
    changeDocumentRequestStatus
}
