import { useState } from "react";
import Loader from "./Loader";
import { IcTotalFinancingReceived } from "@/assets/Icons";
interface BalanceCardProps {
  balance: number;
}

const BalanceCard = ({balance}: BalanceCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <div className="bg-white p-4 grow flex flex-column rounded-lg">
      <div className="flex flex-row gap-4 items-start">
        <IcTotalFinancingReceived />
        <div className="flex flex-column gap-2">
          <span className="body-small-regular text-monochrome-30">Total Financing Received</span>
          {
            isLoading ? <Loader isLoading={isLoading} /> : <h1>{balance} <span className="text-monochrome-30 leading-6 text-base">USD</span></h1>
          }
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
