import { useLocation } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import { classNames } from '@/utils';
import { NavBarList } from '@/constants/NavMenu';
import { ROUTES } from '@/constants';
import Notifications from './Notificaitons/Notifications';
import Button from './UI/Button';


const Header = () => {
    const { pathname } = useLocation();
    const { userId } = useAuth();

    if (!userId) {
        return null;
    }
    const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
    const isProjectOverviewPage = pathname === ROUTES.OVERVIEW;
    if (userId && isSetupProfilePage) {
        return null;
    }

    const onClickBack = () => {
        window.history.back()
    }

    return (
        <header className={classNames("h-16 px-3 py-3 sm:py-0 bg-monochrome-5 flex justify-between items-center gap-y-2 sticky top-0 z-[10]", { hidden: !userId })}>
            <h3 className='my-auto'>
                {isProjectOverviewPage && (<div className='flex items-center gap-2'><Button classNames='bg-white h-fit w-fit !p-[10px] leading-[10px]' rounded onClick={()=> onClickBack()}>{"<"}</Button> <p>Go Back</p></div>)}
                {NavBarList.find((item: any) => { return item.link == pathname })?.title}
                {pathname == "/projects/getCapital" && "Get Capital"}
            </h3>
            <div className='flex flex-col sm:flex-row sm:gap-x-2 items-center gap-y-4'>
                <div className='flex items-center gap-x-6'>
                    <Notifications/>
                </div>
            </div>
        </header>
    )
}

export default Header