import React, { useState, useMemo } from 'react';
import { useQuery } from "@tanstack/react-query";
import Loader from "@components/Loader";
import useAuth from "@/hooks/useAuth";
import ProjectItem from "../../components/Projects/ProjectItem";
import { IcNextPage, IcPreviousPage, IcSearch } from '@/assets';
import { fetchProjects } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/UI/Button';

const perPageItems = [10, 25, 50, 100];

const ProjectsPage: React.FC = () => {
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  const handleGetCapital = () => {
    navigate("/projects/getCapital")
  }
  const { companyId } = useAuth();

  const { data: projects = [], isLoading } = useQuery({
    queryKey: ["getProjects", companyId],
    queryFn: () => fetchProjects(companyId),
    enabled: !!companyId,
    select: (data) => data.data
  });

  // Memoized filtered projects based on search query
  const filteredProjects = useMemo(() => {
    const searchText = searchQuery.toLowerCase();
    return projects.filter((project: any) => {
      const { name = '', status = '', goalAmount = '', receivedAmount = '' } = project;
      return (
        name.toLowerCase().includes(searchText) ||
        status.toLowerCase().includes(searchText) ||
        goalAmount.toString().toLowerCase().includes(searchText) ||
        receivedAmount.toString().toLowerCase().includes(searchText)
      );
    });
  }, [projects, searchQuery]);

  // Function to sort projects by createdAt in descending order
  const sortedProjects = useMemo(() => {
    if (filteredProjects.length > 0) {
      return filteredProjects.slice().sort((a: any, b: any) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
    }
    return [];
  }, [filteredProjects]);

  // Memoized total pages based on sorted projects and per page count
  const totalPages = useMemo(() => {
    return Math.ceil(sortedProjects.length / perPageCount);
  }, [sortedProjects.length, perPageCount]);

  // Memoized displayed projects based on current page and per page count
  const displayedProjects = useMemo(() => {
    const startIndex = (currentPage - 1) * perPageCount;
    return sortedProjects.slice(startIndex, startIndex + perPageCount);
  }, [sortedProjects, currentPage, perPageCount]);

  // Handler for asset tokenization
  const handleTokenizeAsset = () => {
    // Define your logic here for handling asset tokenization
  };

  // Handler to update the project name in the parent component
  const handleUpdateProject = (id: string, newName: string) => {
    // Logic to update project name in state or refetch projects
  };

  const handleClickProject = (clickedProject: any) => {
    navigate(`/projects/overview?projectId=${clickedProject._id}`)
  };

  return (
    <div>
      <div className="bg-white rounded-lg p-4">
        <div className="grid sm:grid-cols-3 justify-between items-center gap-4 sm:gap-0 mb-4">
          <h4>My Projects</h4>
          <div className="sm:col-span-2 flex justify-between gap-4">
            <div className="relative w-[210px] sm:w-[230px] md:w-[300px]">
              <input
                type="text"
                placeholder="Search projects..."
                className="!w-full !border-none body-small-regular !text-[12px] !m-0 !bg-monochrome-5 !rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="absolute top-[9px] right-3 text-monochrome-20">
                <IcSearch />
              </div>
            </div>
            <Button secondary rounded onClick={handleGetCapital}>
              Get Capital
            </Button>
          </div>
        </div>

        <div className="overflow-x-auto lg:overflow-hidden">
          <div className="xl:relative xl:h-[calc(100vh-180px)] w-[220%] lg:w-full">
            <div className="grid grid-cols-5 body-small-regular text-monochrome-30 py-3 border-b border-monochrome-10">
              <span className=''>Name</span>
              <span className="text-end">Received</span>
              <span className="text-end">Goal</span>
              <span className="text-end">Financed</span>
              <span className="text-end">Status</span>
            </div>
            <div className="overflow-y-auto max-h-[calc(100vh-280px)]">
              {isLoading ? (
                <div className="center min-h-20">
                  <Loader isLoading={true} />
                </div>
              ) : sortedProjects.length === 0 ? (
                <div className="center min-h-40"><h6 className="font-normal">No projects available.</h6></div>
              ) : (
                displayedProjects.map((project: any, index: number) => (
                  <ProjectItem
                    key={index}
                    item={project}
                    onUpdateProject={handleUpdateProject}
                    handleClickProject={handleClickProject}
                  // Add your click handler logic for each project item
                  />
                ))
              )}
            </div>
            <div className="xl:absolute xl:bottom-0 w-full flex justify-between pt-[16px] text-[14px]">
              {/* <div className="flex gap-3 items-center">
                <ul className="flex">
                  {perPageItems.map((item: number, index: number) => (
                    <li
                      key={`page-items-${index}`}
                      onClick={() => {
                        setPerPageCount(item);
                        setCurrentPage(1); // Reset current page on per page count change
                      }}
                      className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer bg-monochrome-5 ${item === perPageCount ? 'border-green-100 bg-status-approved text-green-100' : 'border-monochrome-10 text-monochrome-30'
                        }`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <span className="text-monochrome-20 body-small-regular">Items per page</span>
              </div> */}
              {/* <div className="flex items-center gap-2 body-small-regular">
                <span className="text-monochrome-20">Page</span>
                <input
                  className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]"
                  type="text"
                  value={currentPage}
                  min={1}
                  onChange={(e) => setCurrentPage(Number(e.target.value))}
                />
                <span className="text-monochrome-20">of {totalPages+1}</span>
                <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                  <button
                    className="p-2 bg-monochrome-5 rounded-l-lg"
                    onClick={() => setCurrentPage(currentPage > 1 ? currentPage : 1)}
                    disabled={currentPage === 1}
                  >
                    <IcPreviousPage />
                  </button>
                  <button
                    className="p-2 bg-monochrome-5 rounded-r-lg"
                    onClick={() =>
                      setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages+1)
                    }
                    disabled={currentPage === totalPages}
                  >
                    <IcNextPage />
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
