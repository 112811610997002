import React from "react";
import Web3 from "web3";
import IcCross from "@/assets/Icons/ic_cross";
import IcDropdownChecked from "@/assets/Icons/ic_dropdown_checked";

interface TransactionItemProps {
  item: {
    date: string;
    transactionType: string;
    tokenSymbol: string;
    tokenAmount: number;
  };
  onClick: () => void;
}

const TransactionItem: React.FC<TransactionItemProps> = ({ item, onClick }) => {
  // Convert token amount from wei to ether
  const tokenAmountFormatted = Web3.utils.fromWei(item.tokenAmount.toString(), 'ether');

  // Split date and time from the original date string
  const [transactionDate, transactionTime] = item.date.split(', ');

  return (
    <div className="grid grid-cols-5 py-3 body-regular border-b border-monochrome-10 text-monochrome-60 hover:bg-gray-100 cursor-pointer" onClick={onClick}>
      <span >{transactionDate}</span>
      <span className="my-auto capitalize">{item.transactionType}</span>
      <span className="my-auto">{item.tokenSymbol}</span>
      <span >{transactionTime}</span>
      <span className={`flex gap-2 body-medium items-center ${item.tokenAmount < 0 ? "text-red-500" : "text-green-500"}`}>
        {tokenAmountFormatted}
        {item.tokenAmount < 0 ? <IcCross className="h-[16px] w-[16px]" /> : <IcDropdownChecked className="h-[16px] w-[16px]" />}
      </span>
    </div>
  );
};

export default TransactionItem;
