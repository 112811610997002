import OverviewHeader from "./OverviewHeader";
import ProjectStatusBanner from "./ProjectStatusBanner";
import FundingRecievedBanner from "./FundingRecievedBanner";
import InvestorPurchasingCard from "./InvestorPurchasingCard";
import ProjectLastNotifications from "./ProjectLastNotifications";

export default function Overview({ projectData, isLoading = false }: any) {
    const data = projectData

    return (
        <div>
            <OverviewHeader data={data} />
            <div className="flex flex-col gap-4">
                <ProjectStatusBanner data={data} />
                <section className="grid min-h-[145px]">
                    <FundingRecievedBanner data={data} />
                </section>
                <section className="grid grid-cols-1 xl:grid-cols-2 gap-7">
                    <InvestorPurchasingCard data={data} isLoading={isLoading} />
                    <ProjectLastNotifications />
                </section>
            </div>
        </div>
    )
}
