import { fetchTransactions } from "@/services/api";
import Button from "../../UI/Button";
import Loader from "../../Loader";
import { useQuery } from "@tanstack/react-query";
import TransactionItem from "./TransactionItem";
import { Link,useNavigate } from "react-router-dom";
interface ITransactions {
  companyId: string;
  setSelectedTransaction: any;
}
const RecentTransactions = ({ companyId, setSelectedTransaction }: ITransactions) => {
  const navigate = useNavigate();
  const { data: transactions = [], isLoading: isLoadingTransactions } = useQuery({
    queryKey: ["getTransactions", companyId],
    queryFn: () => fetchTransactions(companyId),
    enabled: !!companyId,
    select: (response) => {
      if (response && response.data.length > 0) {
        return response.data
          .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .slice(0, 5);
      }
      return [];
    },
  });
  const navigateToAllTransactions = () => {
    navigate(`/transactions`);
  };
  return (
    <div className="bg-white p-4 flex flex-column rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h5 className="font-medium text-xl">Recent Transactions</h5>
         <Link className="text-monochrome-50 justify-end body-regular pr-0" to="/transactions">View All</Link>
      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="w-[200%] sm:w-full">
          <div className="grid grid-cols-6 text-monochrome-20 body-small-regular py-3 border-b border-monochrome-10">
            <span className="col-span-2">Date</span>
            <span className="col-span-2">Type</span>
            <span>Symbols</span>
            <span className="text-end">Amount</span>
          </div>
          {isLoadingTransactions && (
            <div className="center min-h-20">
              <Loader isLoading={true} />
            </div>
          )}
          {!isLoadingTransactions && transactions.map((transaction: any, index: number) => (
            <TransactionItem
              key={index}
              item={transaction}
              onClick={() => window.open(`${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transaction.hash}`, '_blank')}
            />
          ))}
          {!isLoadingTransactions && transactions.length === 0 && (
            <div className="center min-h-40"><h6 className="font-normal">No transactions available.</h6></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RecentTransactions;