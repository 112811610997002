import { IoLogOutOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const Logout = ({ setShowLogoutModal }: any) => {
  return (
      <NavLink onClick={() => setShowLogoutModal(true)} className="text-[#FF1E1E] flex items-center gap-4 ">
        <IoLogOutOutline className="nav-icon" /> <span>Logout</span>
      </NavLink>
  );
};

export default Logout;
