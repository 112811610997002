const API_BASE_URL = process.env.REACT_APP_PENOMO_BACKEND_API;

export const ENDPOINT = {
  USER: `${API_BASE_URL}/api/users`,
  ASSET_BY_ID: `${API_BASE_URL}/api/assets`,
  ASSET_NEW: `${API_BASE_URL}/api/assets/new`,
  PROJECT: `${API_BASE_URL}/api/projects`,
  PROJECT_NEW: `${API_BASE_URL}/api/projects/new`,
  GET_COMPANY_PROJECTS: `${API_BASE_URL}/api/projects/company`,
  GET_COMPANY_PROJECTS_BY_ID: `${API_BASE_URL}/api/projects`,
  GET_INVESTOR: `${API_BASE_URL}/api/investors`,
  GET_NOTIFICATIONS_PROJECTS_BY_ID: `${API_BASE_URL}/api/projects/notifications`,
  GET_COMPANY: `${API_BASE_URL}/api/companies`,
  DOCS: `${API_BASE_URL}/api/documents`,
  TRANSACTIONS: `${API_BASE_URL}/api/transactions/company`,
  TRANSFER: `${API_BASE_URL}/api/companies/transfer`,
  WEB3_AUTH: `${API_BASE_URL}/api/companies/web3auth`,
  KYC_SUBMIT: `${API_BASE_URL}/api/users/submit/kyc`,
  KYB_SUBMIT: `${API_BASE_URL}/api/companies/kyb/submit`,
  CONTACT_SUPPORT: `${API_BASE_URL}/api/users/contactSupport`,
  NOTIFICATION: `${API_BASE_URL}/api/companies/statusMessages`,
  READ_NOTIFICATION: `${API_BASE_URL}/api/users/notifications/markRead`,
  READ_ALL_NOTIFICATIONS: `${API_BASE_URL}/api/companies/notifications/read-all`
} as const;

export const ROUTES = {
  APP_ROOT: "/",
  DASHBOARD: "/dashboard",
  PROJECTS: "/projects",
  GETCAPITAL: "/projects/getCapital",
  OVERVIEW: "/projects/overview",
  TRANSACTIONS: "/transactions",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  LOGOUT: "/logout",
  PROFILE_SETUP: "/setupProfile",
  WEBSITE: "https://penomo.io",
  DOCS: "https://penomo.notion.site",
  VERIFY_KYC: "/kyc-verification",
  NOTIFICATIONS: "/notifications",
} as const;
