import { useContext } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@services/AuthContext";
import useStore from "@/store/useStore";
import { ROUTES } from "@/constants";
import { web3auth } from "@services/Web3AuthService";

interface LogoutModalProps {
  show: boolean;
  setShowLogoutModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

const LogoutModal = ({ show, setShowLogoutModal, onClose }: LogoutModalProps) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { reset } = useStore();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    try {
      if (web3auth.connected) {
        // Check if the user is connected before logging out
        await web3auth.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    reset(); // reset store data
    setShowLogoutModal(false);
    navigate(ROUTES.APP_ROOT); // Redirect to the home page
    window.location.reload(); //ensure a fresh state
  };

  return (
    <Modal show={show} onHide={onClose} position={"center"} size={"2xl"}>
      <Modal.Body className="p-4">
        <section className="p-4 flex flex-col gap-7 items-center">
          <h3>Are you sure you want to Logout?</h3>
          <div className="flex gap-4 w-full">
            <Button primary rounded onClick={handleLogout} classNames="w-full">
              Yes
            </Button>
            <Button secondary rounded onClick={onClose} classNames="w-full">
              No
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
