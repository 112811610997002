import Loader from "@/components/Loader";
import Button from "@/components/UI/Button";
import InvestorPurchasingItems from './InvestorPurchasingItems';
import { useEffect, useMemo, useState } from "react";
import { getInvestorDetails } from "@/services/api";

const InvestorPurchasingCard = ({ data, isLoading = false }: any) => {
    // const { purchasingRequests = [] } = { ...data };
    const purchasingRequests = useMemo(() => data?.purchasingRequests || [], [data]);


    const [purchasingRequestsData, setPurchasingRequestsData] = useState<any[]>([]);

    const handleRedirectProjects = () => {
        // Implement your view all functionality
    };

    useEffect(() => {
        const fetchInvestorDetails = async () => {
            try {
                const data = await Promise.all(
                    purchasingRequests.map(async (item: any) => {
                        const investorDetails = await getInvestorDetails(item.investorId);
                        return { ...item, investor: investorDetails.name };
                    })
                );
                setPurchasingRequestsData(data);
            } catch (error) {
                console.error("Failed to fetch investor details", error);
                // Handle error appropriately
            }
        };

        // Fetch data only if purchasingRequests has valid entries
        if (purchasingRequests.length > 0) {
            fetchInvestorDetails();
        } else {
            setPurchasingRequestsData([]);
        }
    }, [purchasingRequests]); // Make sure this dependency is stable
    
    return (
        <div className="bg-white p-4 flex flex-col rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h5 className="font-medium text-monochrome-100 text-xl">Last Investor Purchasing Requests</h5>
                <Button transparent classNames="cursor-pointer text-monochrome-50 justify-end body-regular pr-0 hover:bg-transparent" onClick={handleRedirectProjects}>View All</Button>
            </div>
            <div className="overflow-x-auto sm:overflow-hidden">
                <div className="w-[150%] sm:w-full">
                    <div className="grid grid-cols-4 text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
                        <span className="text-start">Investor</span>
                        <span className="text-start">Total Price</span>
                        <span className="text-start">Token Amount</span>
                        <span className="text-end">Status</span>
                    </div>
                    {
                        isLoading && (
                            <div className="center min-h-20">
                                <Loader isLoading={true} />
                            </div>
                        )
                    }
                    <div className="max-h-[calc(5*3.5rem)] overflow-y-auto">
                        {
                            purchasingRequestsData.map((project: any, index: number) => (
                                <InvestorPurchasingItems
                                    key={`project-item-${index}`}
                                    item={project}
                                />
                            ))
                        }
                    </div>
                    {
                        !isLoading && (!purchasingRequestsData || purchasingRequestsData.length === 0) && (
                            <div className="center min-h-40">
                                No purchasing requests
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default InvestorPurchasingCard;
