import { Container, Row, Col } from 'react-bootstrap';
import './InvestmentCard.css';
import twemoji_flag from '../../../assets/twemoji_flag-germany.png'

const OverviewHeader = ({ data }: any) => {
    const { name = "", status = "", assetLocation = "", assetType = "" } = { ...data }

    const statusTypes = {
        Pending: "pending",
        Accepted: "approved",
        Issued: "issued",
        Rejected: "rejected"
    }

    return (
        <Container className="investment-card mb-4 bg-black">
            {/* <Row className="header tab-container" style={{ gap: '10px' }}>
                <Col className="tab">Energy Sales PPA</Col>
                <Col className="tab">Battery Storage</Col>
                <Col className="tab">Carbon Credits</Col>
            </Row> */}
            <Row className="content">
                <Col>
                    <div className="title">{name}</div>
                    <div className='flex items-center mt-[16px] mb-[16px] gap-[40px]'>
                        <div className="subtitle text-gray">{assetType}</div>
                        <div className="country text-gray flex items-center" style={{ gap: "10px" }}>
                            {/* <span role="img" aria-label="Germany">
                                <img style={{ width: "26px", height: "26px" }} src={twemoji_flag} alt='twemoji_flag' />
                            </span>  */}
                            {assetLocation}
                        </div>
                        <div className="country text-gray flex items-center" style={{ gap: "10px" }}>
                            <div className={`bg-status-10 rounded-lg py-1 px-3 caption-medium flex justify-end items-center ml-auto project-status-${statusTypes[status]} !bg-[#00c79817]`}>
                                <div className={`rounded-full w-2 h-2 mr-2`}></div>
                                {status}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default OverviewHeader;
