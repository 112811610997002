import Button from '@/components/UI/Button';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { z } from 'zod';

interface Props {
    currentStep: number;
    setCurrentStep: (value: number) => void;
    setFormDetails: (value: any) => void;
    formDetails: any;
    handleSubmit: () => void;
}

interface IErrors {
    fundingGoal?: string;
}

const capitalNeededSchema = z.object({
    fundingGoal: z.string().min(1, "How much capital needed is required"),
});

const formatNumberWithCommas = (value: string) => {
    const num = value.replace(/\D/g, ''); // Remove non-digit characters
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
};

const removeCommas = (value: string) => {
    return value.replace(/,/g, ''); // Remove commas
};

const CapitalNeededStep = ({ currentStep, setCurrentStep, setFormDetails, formDetails }: Props) => {
    const [errors, setErrors] = useState<IErrors>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        const formattedValue = formatNumberWithCommas(value);

        setFormDetails((prevFormData: any) => ({
            ...prevFormData,
            [name]: removeCommas(formattedValue), // Store the value without commas
        }));

        const fieldValidationResult = capitalNeededSchema.pick({ [name]: true }).safeParse({ [name]: value });
        if (!fieldValidationResult.success) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: fieldValidationResult.error.flatten().fieldErrors[name][0],
            }));
        } else {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors } as any;
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    const handleNext = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const result = capitalNeededSchema.safeParse(formDetails);

        if (!result.success) {
            setErrors(result.error.flatten().fieldErrors);
            toast.error("Please correct the errors before submitting.");
            return;
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <>
            <h4>How much capital do you need?</h4>
            <form onSubmit={handleNext}>
                <div className='relative mt-6 body-small-regular'>
                    <label className='text-sm'>Capital needed (amount in USD)</label>
                    <input 
                        type="text" 
                        name="fundingGoal" 
                        className={`mt-2 w-full pl-6 border-monochrome-10`} 
                        value={formDetails?.fundingGoal ? formatNumberWithCommas(formDetails.fundingGoal) : ''} 
                        onChange={(e) => handleChange(e)} 
                    />
                    <p className='absolute bottom-2 left-2 text-monochrome-60'>$</p>
                    <p className='absolute bottom-2 right-2 text-monochrome-60'>USD</p>
                </div>
                {errors.fundingGoal && <p className="text-red-500 mt-1">{errors.fundingGoal}</p>}
                <Button secondary rounded type='submit' classNames="mt-6">Next</Button>
            </form>
        </>
    );
};

export default CapitalNeededStep;
