// App.js
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";

// Importing auth services
import { QueryProvider } from "@services/QueryProvider";
import "./utils/global";

// Importing styling
import Layout from "./pages/layout";
import { AuthProvider } from "./services/AuthContext";

function App() {

  return (
    <AuthProvider>
      <QueryProvider>
        <Router>
          <Layout />
          <Toaster />
        </Router>
      </QueryProvider>
    </AuthProvider>
  );
}

export default App;
