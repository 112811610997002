const IcNotification = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0167 17.1082C8.07505 17.1082 6.13339 16.7999 4.29172 16.1832C3.59172 15.9416 3.05839 15.4499 2.82505 14.8082C2.58339 14.1666 2.66672 13.4582 3.05005 12.8249L4.00839 11.2332C4.20839 10.8999 4.39172 10.2332 4.39172 9.84156V7.43323C4.39172 4.33323 6.91672 1.80823 10.0167 1.80823C13.1167 1.80823 15.6417 4.33323 15.6417 7.43323V9.84156C15.6417 10.2249 15.8251 10.8999 16.0251 11.2416L16.9751 12.8249C17.3334 13.4249 17.4001 14.1499 17.1584 14.8082C16.9167 15.4666 16.3917 15.9666 15.7334 16.1832C13.9001 16.7999 11.9584 17.1082 10.0167 17.1082ZM10.0167 3.05823C7.60839 3.05823 5.64172 5.01656 5.64172 7.43323V9.84156C5.64172 10.4499 5.39172 11.3499 5.08339 11.8749L4.12505 13.4666C3.94172 13.7749 3.89172 14.0999 4.00005 14.3749C4.10005 14.6582 4.35005 14.8749 4.69172 14.9916C8.17505 16.1582 11.8667 16.1582 15.3501 14.9916C15.6501 14.8916 15.8834 14.6666 15.9917 14.3666C16.1001 14.0666 16.0751 13.7416 15.9084 13.4666L14.9501 11.8749C14.6334 11.3332 14.3917 10.4416 14.3917 9.83323V7.43323C14.3917 5.01656 12.4334 3.05823 10.0167 3.05823Z"
        fill="#3F3F3F"
      />
      <path
        d="M11.567 3.28325C11.5086 3.28325 11.4503 3.27491 11.392 3.25825C11.1503 3.19158 10.917 3.14158 10.692 3.10825C9.98363 3.01658 9.3003 3.06658 8.65863 3.25825C8.4253 3.33325 8.1753 3.25825 8.01697 3.08325C7.85863 2.90825 7.80863 2.65825 7.9003 2.43325C8.24196 1.55825 9.0753 0.983246 10.0253 0.983246C10.9753 0.983246 11.8086 1.54991 12.1503 2.43325C12.2336 2.65825 12.192 2.90825 12.0336 3.08325C11.9086 3.21658 11.7336 3.28325 11.567 3.28325Z"
        fill="#3F3F3F"
      />
      <path
        d="M10.0171 19.0083C9.19209 19.0083 8.39209 18.675 7.80876 18.0916C7.22542 17.5083 6.89209 16.7083 6.89209 15.8833H8.14209C8.14209 16.375 8.34209 16.8583 8.69209 17.2083C9.04209 17.5583 9.52542 17.7583 10.0171 17.7583C11.0504 17.7583 11.8921 16.9166 11.8921 15.8833H13.1421C13.1421 17.6083 11.7421 19.0083 10.0171 19.0083Z"
        fill="#3F3F3F"
      />
    </svg>
  );
};

export default IcNotification;
