import { useState } from 'react'
import Details from '@/components/Projects/Details'
import Overview from '@/components/Projects/Overview'
// import RevenueManagement from '@/components/Projects/RevenueManagement'
import ProjectTabs from '@/components/Projects/Tabs/Tabs'
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchProjectById } from '@/services/api';
// import TokenManagement from '@/components/Projects/TokenManagement'

export default function OverviewPage() {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId') || "";


    const { data: projectData, isLoading, refetch } = useQuery({
        queryKey: ["fetchProjectById", projectId],
        queryFn: () => fetchProjectById(projectId),
        enabled: !!projectId,
        select: (res) => res.data
    });

    const [currentTab, setCurrentTab] = useState("Overview")
    const tabList = ["Overview", "Details"]
    // const tabList = ["Overview", "Details", "Token Management", "Revenue Management"]

    function SelectedTab({ currentTab }: any) {
        if (currentTab === "Overview") {
            return <Overview projectData={projectData} isLoading={isLoading} />
        } else if (currentTab === "Details") {
            return <Details projectData={projectData} isLoading={isLoading} refetch={refetch} />
        }
        // else if (currentTab === "Token Management") {
        //     return <TokenManagement />
        // } else if (currentTab === "Revenue Management") {
        //     return <RevenueManagement />
        // }
    }

    return (
        <div>
            <ProjectTabs onSelectTab={setCurrentTab} tabList={tabList} uniqueId="main-tab-component" />
            <SelectedTab currentTab={currentTab} />
        </div>
    )
}
