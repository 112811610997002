import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

// Importing page components and ProtectedRoute
import HomePage from './HomePage';
import DashboardPage from './DashboardPage';
import GetCapital from './Projects/GetCapital';
import ProjectsPage from './Projects/ProjectsPage';
import SettingsPage from './SettingsPage';
import ProfilePage from './ProfilePage';

// Importing common components
import NavBar from '@/components/NavBar';
import Header from '@/components/Header';

import ProtectedRoute from '@/services/ProtectedRoute';
import ExitConfirmationModal from '@/components/Projects/GetFinancial/ExitConfirmationModal';

import useStore from '@/store/useStore';
import { ROUTES } from '@/constants';
import TransactionsPage from './Transactions/TransactionsPage';
import ContactSupportModal from '@/components/ContactSupport/ContactSupport';
import ContactSupportSuccessModal from '@/components/ContactSupport/SucessModal';
import Notifications from './Notifications/Notifications';
import OverviewPage from './Projects/Overview';
import LogoutModal from '@/components/LogoutModal';


type Props = {};

const Layout = (props: Props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);
    const [showLogoutModal,setShowLogoutModal]=useState<boolean>(false);
    const [showContactModal, setShowContactModal] = useState<boolean>(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const { userData } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const isSetupProfilePage = location.pathname === ROUTES.PROFILE_SETUP;
    // Track the current path
    const [currentPath, setCurrentPath] = useState(location.pathname);

    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        const web3AuthToken = localStorage.getItem("web3AuthToken");
        if (authToken && web3AuthToken) {
            setIsLoggedIn(true);
        }
    }, [userData]);

    useEffect(() => {
        // Update the current path when the location changes
        setCurrentPath(location.pathname);
    }, [location]);

    useEffect(()=>{
        if (userData && !userData.isProfileExisting) {
            navigate('/setupProfile');
          }
    },[])
    
    const handleCloseContactModal = (isSuccess: boolean) => {
        setShowSuccessDialog(isSuccess);
        setShowContactModal(false);
    };

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        e.preventDefault();
        if (path == ROUTES.SUPPORT) {
            setShowContactModal(true);
        } else if (currentPath === ROUTES.GETCAPITAL) {
                // Only show exit confirmation if the current path is GetCapital
                setPendingNavigation(path);
                setShowExitConfirmation(true);
            } else {
                navigate(path);
            }
    };

    const handleExitConfirmation = (confirm: boolean) => {
        setShowExitConfirmation(false);
        if (confirm && pendingNavigation) {
            navigate(pendingNavigation);
        }
        setPendingNavigation(null);
    };
 
    return (
        <main className="flex flex-col lg:flex-row h-screen mx-auto relative">
            <NavBar handleLinkClick={handleLinkClick} setShowLogoutModal={setShowLogoutModal} />
            <section className={`w-full h-[100%] !h-fit relative  ${isLoggedIn && !isSetupProfilePage ? 'xl:ml-60' : ''}`}>
                <Header />
                <div className="p-4 mx-auto">
                    <Routes>
                        <Route path={ROUTES.APP_ROOT} element={<HomePage />} />
                        <Route
                            path={ROUTES.DASHBOARD}
                            element={
                                <ProtectedRoute>
                                    <DashboardPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTES.PROJECTS}
                            element={
                                <ProtectedRoute>
                                    <ProjectsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={ROUTES.OVERVIEW} element={<OverviewPage />} />
                        <Route path={ROUTES.GETCAPITAL} element={<GetCapital handleLinkClick={handleLinkClick} />} />
                        <Route
                            path={`${ROUTES.PROJECTS}/:pid`}
                            element={
                                <ProtectedRoute>
                                    <ProjectsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTES.TRANSACTIONS}
                            element={
                                <ProtectedRoute>
                                    <TransactionsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTES.SETTINGS}
                            element={
                                <ProtectedRoute>
                                    <SettingsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTES.PROFILE_SETUP}
                            element={
                                <ProtectedRoute>
                                    <ProfilePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTES.NOTIFICATIONS}
                            element={
                                <ProtectedRoute>
                                    <Notifications />
                                </ProtectedRoute>
                            }
                        /> 
                    </Routes>
                </div>
            </section> 

            <ExitConfirmationModal
                show={showExitConfirmation}
                onClose={() => setShowExitConfirmation(false)}
                onConfirm={() => handleExitConfirmation(true)}
            />
            <LogoutModal show={showLogoutModal} setShowLogoutModal={setShowLogoutModal} onClose={()=>setShowLogoutModal(false)} />
            {showContactModal && <ContactSupportModal open={showContactModal} onClose={handleCloseContactModal} />}
            <ContactSupportSuccessModal show={showSuccessDialog} handleClose={() => setShowSuccessDialog(false)} />
        </main>
    );
};

export default Layout;
