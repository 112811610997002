import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IcCross from "@/assets/ic_cross";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ROUTES } from "@/constants";
import useStore from "@/store/useStore";
import dayjs from "dayjs";
import { fetchAllNotifications, readNotification } from "@/services/api";
import useClickAway from "@/hooks/useClickAway";
import Loader from "../Loader";
import { IcNotification } from "@/assets/menu";
import Button from "../UI/Button";
import ExitConfirmationModal from "@/components/Projects/GetFinancial/ExitConfirmationModal";
import { IcPercentage, IcRedirect } from "@/assets/Icons";

const Notifications = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const queryClient = useQueryClient();
  const [toggleNotifications, setToggleNotifications] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [crossIcon, setShowCrossIcon] = useState(-1);
  const notificationsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const { userData } = useStore();
  const id = userData?._id || userData?.investorId;

  const { data: notification = [], refetch } = useQuery({
    queryKey: ["getNotifications", id],
    queryFn: () => fetchAllNotifications(id),
    enabled: userData && !!id,
    select: (data) => data.data,
    refetchOnWindowFocus: true
  });

  // Sort notifications by createdAt or updatedAt in descending order
  const sortedNotifications = notification.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

  const mostRecent = sortedNotifications.slice(0, 3).map((item: any) => ({
    ...item,
    icon: "bi bi-coin",
    color: "#1DD05D",
  }));

  useClickAway(notificationsRef, () => {
    setToggleNotifications(false);
  });

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    setToggleNotifications(false);
  }, [location.pathname]);

  const handleDeleteClick = async (notificationId: any, type: any) => {
    setDeleteLoading(true);
    await readNotification(notificationId.toString());
    await refetch();
    queryClient.invalidateQueries(["getNotification"]);
    setDeleteLoading(false);
  };

  const showCrossIcon = (index: any) => {
    setShowCrossIcon(index);
  };

  const hideCrossIcon = () => {
    setShowCrossIcon(-1);
  };

  const handleNagivation = (path: string) => {
    navigate(path);
  };

  const handleShowNotificationPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (currentPath === ROUTES.GETCAPITAL) {
      setShowExitConfirmation(true);
    } else {
      navigate("/notifications");
    }
  };

  const handleExitConfirmation = (confirm: boolean) => {
    setShowExitConfirmation(false);
    if (confirm) {
      navigate("/notifications");
    }
  };

  const NotificationComponent = ({ notificationList }: any) => {
    return (
      <>
        <div className="w-[294px] sm:w-[450px]">
          {notificationList.map((item: any, index: any) => (
            <div
              className="flex justify-between items-center p-4 hover:bg-monochrome-10"
              key={index}
              onMouseEnter={() => showCrossIcon(index)}
              onMouseLeave={() => hideCrossIcon()}
            >
              <div className="flex justify-between gap-2 text-sm">
                <IcPercentage />
                <div style={{ maxWidth: "380px" }}>
                  <p className="mb-3 text-sm leading-auto">
                    {item.messages.length > 60 ? item.messages.substring(0, 60) + ". . ." : item.messages}{" "}
                    <span className="text-monochrome-20">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                  </p>
                  {(item.status === "Complete KYC" || item.status === "Complete KYB") && (
                    <Button
                      primary
                      rounded
                      onClick={() => {
                        handleNagivation("/settings");
                      }}
                    >
                      <p className="text-sm leading-auto">{item.status}</p> <IcRedirect />
                    </Button>
                  )}
                </div>
              </div>
              {crossIcon === index && <IcCross style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(item._id, "user")} />}
            </div>
          ))}
          <div className="flex justify-center w-full">
            <Button classNames="decoration-none py-3 hover:bg-transparent" onClick={handleShowNotificationPage}>
              See all notifications
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {deleteLoading && (
        <div className="min-h-[40px] loader-center">
          <Loader isLoading={true} svgStyle={{ width: "2.5rem", height: "2.5rem" }} />
        </div>
      )}
      <div className="relative">
        <button
          type="button"
          className="relative bg-inherit hover:bg-transparent inline-flex items-center text-sm font-medium text-center rounded-lg"
          onClick={() => setToggleNotifications(!toggleNotifications)}
        >
          <IcNotification />
          <span className="w-[12px] h-[12px] block text-[10px] text-white leading-[12px] bg-green-100 rounded-[20px] absolute top-[0] right-[0]">
            {notification.length}
          </span>
          <span className="sr-only">Notifications</span>
        </button>

        {toggleNotifications && (
          <div
            ref={notificationsRef}
            className="absolute right-0 mt-8 w-96 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
            style={{ width: "fit-content", zIndex: 99 }}
          >
            <NotificationComponent notificationList={mostRecent} />
          </div>
        )}
        <ExitConfirmationModal show={showExitConfirmation} onClose={() => setShowExitConfirmation(false)} onConfirm={() => handleExitConfirmation(true)} />
      </div>
    </>
  );
};

export default Notifications;
