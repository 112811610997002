import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import toast from "react-hot-toast";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  Conflict = 409,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "X-Requested-With": "XMLHttpRequest",
};

const injectToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  try {
    const token = localStorage.getItem("authToken");

    if (token != null && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error: unknown) {
    const { message } = error as Error;
    throw new Error(message);
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance ?? this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_PENOMO_BACKEND_API ?? "http://localhost:3000",
      timeout: 20000,
      headers,
      withCredentials: false,
    });

    http.interceptors.request.use(injectToken, (error) => Promise.reject(new Error(error.message)));

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private handleError(error: any) {
    if (axios.isAxiosError(error)) {
      const { response } = error;

      if (response) {
        const { status, data } = response;

        switch (status) {
          case StatusCode.InternalServerError:
            toast.error("Internal Server Error");
            break;
          case StatusCode.Forbidden:
            localStorage.clear();
            window.location.href = "/";
            break;
          case StatusCode.Unauthorized:
            localStorage.clear();
            window.location.href = "/";
            break;
          case StatusCode.TooManyRequests:
            break;
          case StatusCode.Conflict:
            return Promise.reject({ ...response, message: data.message });
          default:
            toast.error("An unexpected error occurred");
            break;
        }
      }
    }
    if(error && error.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
